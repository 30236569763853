<style scoped>
.modal {
  align-items: initial;
}

.affair-status-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.affair-status-container > :nth-child(2) {
  margin-left: 10px;
}

.affair-status {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #1e73be11, #00a1cf11);
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 10px auto 15px auto;
}

.affair-status span {
  background: -webkit-linear-gradient(#1e73be, #00a1cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  text-align: center;
}

.affair-status-icon {
  color: #1e73be;
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.modal-menu-head button {
  margin: 10px auto 15px auto;
}

.modal-menu-title {
  text-align: center;
  color: rgb(50, 60, 70);
  font-size: 13px;
  margin-top: 30px;
}

.affair-number {
  text-align: center;
  color: rgb(50, 60, 70);
  font-size: 18px;
  margin-top: 5px;
  font-weight: 600;
}

.spindle-tab {
  padding: 15px 20px 0px 20px;
}

.radio-button-container {
  flex-shrink: 0;
}

.inputeo {
  width: 380px;
}

.radiobutton-container {
  flex: initial;
  margin-bottom: 10px;
}

.photos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.no-photos {
  display: inline-block;
  width: 200px;
  margin: 10px 0 5px 0;
  padding: 10px 20px;
  color: #bcbcbc;
  background-color: #f4f4f4;
  border-radius: 10px;
}

.photo-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photo-topbar-title {
  font-size: 15px;
  font-weight: 500;
  color: rgb(50, 60, 70);
}

#photo-section {
  margin: -30px -15px 15px -15px;
}

#photo-section > div {
  width: 50%;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: auto;
}

.radiobutton-container label {
  display: block;
}

.textareas > * {
  width: initial;
  flex: 1;
}

.radio-button input:checked + div {
  background: linear-gradient(90deg, #1e73be, #00a1cf) !important;
}

.multiselect__tags {
  padding-top: 5px;
}

.multiselect__placeholder {
  margin-bottom: 0;
}

.multiselect__select {
  height: 32px;
  padding: 0px 8px;
}

.multiselect {
  min-height: initial;
  height: 32px;
}

.textareas textarea {
  min-height: 80px !important;
}

.form-buttons {
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background-color: white;
  padding: 10px;
  z-index: 999;
  width: 100%;
}

.infinite-list {
  width: 100%;
}

.infinite-list .card:not(:last-child) {
  margin-bottom: 20px;
}

.infinite-list .card {
  padding: 20px;
}

.infinite-list .card hr {
  width: calc(100% + 40px);
  margin: 10px 0 10px -20px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgb(220, 225, 230);
}

.infinite-list .affair-number {
  margin: 0;
}

.infinite-list .affair-status {
  padding: 5px 12px;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  margin: 8px auto;
  border-radius: 4px;
}

.infinite-list .affair-info {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  align-items: center;
}

.infinite-list .affair-info span:first-child {
  font-size: 12px;
  color: rgb(50, 60, 70);
  padding-right: 10px;
  flex: 1;
  box-sizing: border-box;
}

.infinite-list .affair-info span:last-child {
  text-align: right;
  font-size: 14px;
  color: rgb(30, 40, 50);
  font-weight: 600;
  flex: 1;
  box-sizing: border-box;
  padding-left: 10px;
}

.infinite-list .downloads {
  text-align: center;
  color: rgb(50, 60, 70);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infinite-list .downloads svg {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  fill: rgb(50, 60, 70);
}

.infinite-list .card-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.infinite-list .card-buttons *:last-child:nth-child(odd) {
  width: 100%;
}

.infinite-list .card-buttons > button {
  font-weight: 600;
  border: none;
  color: white;
  font-size: 11px;
  padding: 10px 15px;
  border-radius: 4px;
  text-transform: uppercase;
  width: calc(50% - 5px);
  margin-top: 10px;
  text-align: center;
}

#clocking-tab #clocking-tab-title {
  font-size: 24px;
  color: rgb(50, 60, 70);
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  justify-content: center;
}

#clocking-tab #clocking-tab-subtitle {
  font-size: 14px;
  color: rgb(50, 60, 70);
  margin-bottom: 5px;
  font-weight: 500;
  justify-content: center;
}

#clocking-tab #clocking-tab-subtitle-2 {
  font-size: 11px;
  color: rgb(50, 60, 70);
  margin-bottom: 20px;
  justify-content: center;
}

#analytics-tab #analytics-tab-title {
  font-size: 24px;
  color: rgb(50, 60, 70);
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  justify-content: center;
}

#analytics-tab #analytics-tab-subtitle {
  font-size: 14px;
  color: rgb(50, 60, 70);
  margin-bottom: 5px;
  font-weight: 500;
  justify-content: center;
}

#analytics-tab #analytics-tab-subtitle-2 {
  font-size: 11px;
  color: rgb(50, 60, 70);
  margin-bottom: 20px;
  justify-content: center;
}

.order-document-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.order-document-tab #quotation-invoice-status-date {
  font-size: 18px;
  display: block;
  margin-top: 10px;
}

.order-document-tab #quotation-invoice-status-text {
  font-weight: 700;
}

.order-document-tab button {
  margin: 10px auto;
}

#clocking-tab table,
#repair-tab table,
#analytics-tab table {
  text-align: left;
  border-collapse: collapse;
}

#clocking-tab table,
#analytics-tab table,
#repair-tab #repair-line-article-container table,
#repair-tab #repair-line-article-linked-container table {
  width: 100%;
}

#clocking-tab table thead,
#repair-tab table thead,
#analytics-tab table thead {
  background-color: rgb(240, 243, 246);
  color: rgb(100, 110, 120);
  font-size: 11px;
  text-transform: uppercase;
}

#clocking-tab table thead th,
#repair-tab table thead th,
#analytics-tab table thead th {
  font-weight: 500;
  padding: 13px 20px;
  white-space: nowrap;
}

#clocking-tab table tbody tr,
#repair-tab table tbody tr,
#analytics-tab table tbody tr {
  border-bottom: 1px solid rgb(230, 235, 240);
}

#clocking-tab table tbody td,
#repair-tab table tbody td,
#analytics-tab table tbody td {
  font-weight: 500;
  font-size: 13px;
  color: rgb(30, 40, 50);
}

#clocking-tab table tbody td,
#analytics-tab table tbody td {
  padding: 15px 20px;
}

#repair-tab table tbody td {
  padding: 5px 20px;
  height: 55px;
}

#repair-tab table thead th:not(:first-child),
#repair-tab #repair-line-article-container table tbody td:not(:first-child),
#repair-tab #repair-line-article-linked-container table tbody td:not(:first-child) {
  text-align: center;
}

#repair-tab table#table-state thead th,
#repair-tab table#table-state tbody td {
  text-align: center;
}

#clocking-tab .button {
  margin: 0px !important;
}

#form-nomenclature {
  justify-content: flex-start;
}

#form-nomenclature :deep(.article-container) {
  display: flex;
  justify-content: space-between;
  max-width: 15vw;
}

#form-nomenclature :deep(.articles-list .inputeo) {
  margin: 0px 10px;
  width: 100px;
}

#form-nomenclature .article-detail-container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

#form-nomenclature .article-detail-container > div:nth-child(1) {
  flex: 3;
  margin: 0px 10px 0px 0px;
}

#form-nomenclature .article-detail-container > div:nth-child(4) {
  flex: 3;
  margin: 0px 0px 0px 10px;
}

#form-nomenclature .article-detail-container > div:nth-child(2),
.article-detail-container > div:nth-child(3) {
  flex: 2;
  margin: 0px 10px;
}

#form-nomenclature > div {
  justify-content: flex-start;
  flex: 2;
}

#form-nomenclature > div:first-child {
  flex: 1.3;
}

#form-nomenclature > div:last-child {
  flex: 3;
}

#form-nomenclature :deep(.inputeo) {
  width: 100%;
}

#form-nomenclature .article-stock-detail {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 100;
}

#form-nomenclature .article-stock-check {
  color: #4fa601;
  margin-right: 3px;
}

#nomenclature-not-completed,
#no-reparation,
#quotation-not-available,
#ar-order-not-available,
#pro-forma-not-available,
#invoice-not-available,
#delivery-form-not-available,
#conformity-report-not-available {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
}

#nomenclature-not-completed button {
  margin-top: 25px;
}

#form-nomenclature #nomenclature-button-document-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#form-nomenclature #nomenclature-container-add-article {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#form-nomenclature #nomenclature-container-add-article-selectors {
  display: column;
  justify-content: space-around;
  width: 100%;
}

#form-nomenclature #nomenclature-container-add-article-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.expert-assessment-planning-tab {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.expert-assessment-technician-div {
  background-color: rgb(240, 240, 240);
  width: 300px;
  padding: 35px;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
}

.text-container {
  background-color: rgb(240, 240, 240);
  width: 300px;
  padding: 35px;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
}

.expert-assessment-technician-name {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-top: 10px;
}

.expert-assessment-date {
  font-size: 18px;
  display: block;
  margin-top: 20px;
}

.expert-assessment-tab,
#conformity-report-tab {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

#download-expert-assessment-div, #download-intervention-report-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#download-expert-assessment-div .inputeo, #download-intervention-report-div .inputeo {
  margin: 20px 10px 20px 10px;
}

#repair-tab .repair-line-container {
  margin: 6px 0px;
}

#repair-tab .repair-icon-ok {
  color: #4ea600;
  margin-left: 15px;
  font-size: 18px;
}

#repair-tab .repair-icon-ko {
  color: #ff416b;
  margin-left: 15px;
  font-size: 18px;
}

#repair-tab #repair-line-article-container, #repair-tab #repair-line-article-linked-container {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}

#repair-tab :deep(.inputeo) {
  width: 160px;
  margin: auto;
  min-width: unset;
}

.mobile-filter-container {
  margin-bottom: 20px;
}

.container-groups-family-articles {
  width: 100%;
}

.container-groups-family-articles > div:not(:last-child) {
  margin-bottom: 25px;
}

.articles-group {
  margin-top: 15px;
}

.list-with-delete-container {
  margin: 10px 0 0 10px;
  width: 99%;
}

#quotation_validation_modal #validation_modal_content {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#quotation_validation_modal .validation_modal_text {
  text-align: center;
}

#quotation_validation_modal #validation_modal_choice {
  width: 96%;
  margin: 15px 2%;
  min-height: 150px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

#quotation_validation_modal #validation_modal_choice .radio-button-container {
  margin: 0 3%;
}

#quotation_validation_modal #last_validation {
  padding: 0;
  width: 100%;
  float: right;
  margin: 120px 0 0 0;
}

#doc-history {
  margin: 10px 15px;
}

#doc-history #doc-history-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 35px auto 15px auto;
  text-align: center;
  padding: 10px 20px;
  background-color: #eee;
  width: 550px;
  font-size: 17px;
  color: rgb(50, 60, 70);
}

#doc-history #doc-history-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

#doc-history #doc-history-content h2 {
  text-align: center;
  margin-bottom: 25px;
}

#doc-history .doc-history-column {
  width: 350px;
  margin: 15px 5px;
  max-height: 75vh;
  overflow-y: auto;
}

#doc-history .doc-history-item {
  background-color: white;
  margin: 15px 10px;
  padding: 5px 10px 5px 0;
  border-radius: 10px;
  box-shadow: 0 3px 10px -2px rgb(0 20 40 / 35%);
  display: flex;
  font-size: 15px;
  font-weight: 500;
  transition: background-color 0.3s ease-out;
}

#doc-history .doc-history-item-null {
  background-color: #f2f2f2;
  margin: 15px 10px;
  padding: 5px 10px 5px 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
}

#doc-history .doc-history-item-null div {
  margin: 10px;
}

#doc-history .doc-history-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

#doc-history .doc-history-item .doc-history-item-logo {
  margin: auto 20px;
  width: 20px;
}

#input-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#input-section .affair-form-row {
  width: 100%;
  display: grid;
  column-gap: 20px;
  flex-direction: row;
  justify-content: center;
}

#input-section .affair-form-row .radiobutton-container {
  margin: 0;
}

#input-section .affair-form-row .inputeo {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  max-height: 60px;
}

.inputeo.input-to-create {
  border: 2px solid orange;
}

.quotation-infos {
  max-width: 600px;
  overflow-x: hidden;
  margin: 20px 0 0 0;
}

.quotation-infos > .inputeo {
  width: 96%;
  margin: 0 2% 10px 2%;
}

.quotation-infos .documents-list {
  width: 96%;
  margin: 0 2% 0 2%;
}

.quotation-infos .inputeo-icon {
  min-width: 85%;
  margin: auto;
  overflow-x: hidden;
}

.column-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.light-affair-grid {
  display: grid;
  flex-direction: row;
  justify-content: center;
}

.light-affair-grid .light-affair-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 5px
}

.take-new-pictures-btn {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.take-new-pictures-btn + label {
  font-weight: 500;
  border: none;
  color: white;
  font-size: 12px;
  padding: 9px 15px;
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease;
  background: linear-gradient(90deg, #ff6b00, #ff9100);
  font-family: Montserrat;
}

.take-new-pictures-btn + label svg {
  margin-right: 6px;
}

#topbar-button-container {
    display: flex;
}

#topbar-button-container :deep(button:first-child) {
    margin-right: 20px;
}

.multi-row-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.multi-row-filters .row-filter {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}

:deep(.list-with-delete) {
    max-height: unset !important;
}
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Générer bilan(s)" @click="openBilanGenerationModal" v-if="$store.getters.isAdmin || $store.getters.isWorkshopManager">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'receipt']"/>
                    </template>
                </BaseButton>
                <BaseButton buttonText="Nouvelle affaire" @click="openCreateAffairModal"
                              v-if="$store.getters.isAdmin || $store.getters.isWorkshopManager">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']"/>
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>

  <div v-if="$store.getters.isCustomer && $store.getters.isMobile">
    <div class="mobile-filter-container">
      <BaseCheckbox @onChange="onArchivedAffairChanged" label="Afficher les affaires archivées"/>
    </div>
    <InfiniteList :apiParams="filter" apiRoute="affair/list" name="affairs" :key="updateMobileAffairList">
      <template v-slot:itemContainer="{ item }">
        <Card>
          <div class="affair-number">{{ item.affairNumber }}</div>
          <div class="affair-status" v-if="item.status">
            <font-awesome-icon
                :icon="$store.getters.isCustomer ? $store.getters.getAffairStatusList[item.status].customerIcon : $store.getters.getAffairStatusList[item.status].icon"
                class="affair-status-icon"
            />
            <span>
                            {{
                $store.getters.isCustomer ? $store.getters.getAffairStatusList[item.status].customerLabel : $store.getters.getAffairStatusList[item.status].label
              }}
                        </span>
          </div>
          <hr/>
          <div class="affair-info">
            <span>Date d'entrée</span>
            <span>{{ item.receiptDate }}</span>
          </div>
          <div class="affair-info">
            <span>Marque</span>
            <span>{{ item.spindle?.spindleType.spindleBrand.name }}</span>
          </div>
          <div class="affair-info">
            <span>Type</span>
            <span>{{ item.spindle?.spindleType.name }}</span>
          </div>
          <hr/>
          <div class="downloads">
            <font-awesome-icon :icon="['fas', 'arrow-to-bottom']" class="icon icon-times"/>
            Téléchargements
          </div>
          <div class="card-buttons">
            <BaseButton
                :disabled="item.expertAssessmentPath == null"
                @click="customerDownloadExpertAssessment(item.expertAssessmentPath)"
                buttonClass="dark-blue-button"
                :buttonText="`Rapport d'expertise`"
                title
            ></BaseButton>
            <BaseButton
                :disabled="item.conformityReportPath == null"
                @click="customerDownloadConformityReport(item.conformityReportPath)"
                buttonClass="dark-blue-button"
                buttonText="PV de conformité"
                title
            ></BaseButton>
            <BaseButton
                :disabled="item.quotationPath == null"
                @click="customerDownloadQuotation(item.quotationPath)"
                buttonClass="blue-button"
                buttonText="Devis"
                title
            ></BaseButton>
            <BaseButton
                :disabled="item.invoicePath == null"
                @click="customerDownloadInvoice(item.invoicePath)"
                buttonClass="orange-button"
                buttonText="Facture"
                title
            ></BaseButton>
            <BaseButton
                v-if="item.orderDocumentIdToValidate && !$store.getters.isTechnician"
                @click="openQuotationResponse(item.orderDocumentIdToValidate)"
                buttonClass="orange-button"
                buttonText="Valider/Refuser le devis"
                title="Valider/Refuser le devis"
            ></BaseButton>
          </div>
        </Card>
      </template>
    </InfiniteList>
  </div>

  <Aggrid
      :columnDefs="columnDefs"
      :frameworkComponents="frameworkComponents"
      :cellRendererFunctions="cellRendererFunctions"
      :context="context"
      apiRoute="affair/list"
      :apiParams="filter"
      @rowClicked="((event) => {
            if (event?.classicAffair) {
                prepareEditAffair(event.id)
            } else {
                displayLightAffair(event)
            }
        })"
      aggridHeightSubstract="175px"
  >
    <template v-slot:filter>
      <div class="multi-row-filters">
        <div class="row-filter">
            <div>
                <BaseCheckbox label="Afficher les affaires supprimées" @onChange="displayDeletedAffairs" v-if="$store.getters.isAdmin || $store.getters.isWorkshopManager"/>
            </div>
            <div>
            <BaseInput v-model="search" label="Rechercher" type="text" name="search"
                       @onChange="onSearchInputChange">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']"/>
              </template>
            </BaseInput>
          </div>
          <div>
            <BaseInput v-model="affairNumberSearch" label="Numéro de l'affaire" type="text" name="affairNumberSearch"
                       @onChange="onSearchInputChange">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']"/>
              </template>
            </BaseInput>
          </div>
          <div>
            <BaseInput v-model="affairLabelSearch" label="Libellé de l'affaire" type="text" name="affairLabelSearch"
                       @onChange="onSearchInputChange">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']"/>
              </template>
            </BaseInput>
          </div>
        </div>
        <div class="row-filter">
            <div>
            <BaseSearchingInput
                v-model="affairSerialNumberSearch"
                label="Numéro de série de la broche"
                type="text"
                name="affairSerialNumber"
                api="spindle/search"
                fieldId="id"
                fieldValue="serialNumber"
                @onChange="onFiltersChange($event)"
            />
          </div>
          <div>
            <BaseSelect
                v-model="affairGuaranteeStatusSearch"
                label="Garantie de l'affaire"
                :defaultOptions="affairGuaranteeStatus"
                name="affairGuaranteeStatus"
                @onChange="onFiltersChange($event)"
                :maxHeight="300"
            />
          </div>
          <div>
            <BaseSelect
                v-model="affairStatusSearch"
                label="Statut de l'affaire"
                :defaultOptions="affairStatus"
                name="affairStatus"
                @onChange="onFiltersChange($event)"
                :maxHeight="300"
            />
          </div>
        </div>
      </div>
    </template>
  </Aggrid>

  <Modal :fullscreen="true" mode="menu" v-if="!$store.getters.isCustomer" v-show="displayAffairModal === true">
    <template v-slot:modalMenu>
      <ModalMenu :forceCurrentTab="currentTab" @preloadData="getOrderDocuments" :changeModeParent="true"
                 :menuItems="menuItems" :startTab="startAffairModalTab" :repairType="affair.repairType?.value"
                 @onExit="closeAffairModal" ref="affairModalMenu" v-on:update:startTab="startAffairModalTab = $event">
        <template v-slot:modalMenuHead>
          <div class="modal-menu-title">Dossier de l'affaire</div>

          <div class="affair-number">{{ affair.affairNumber }}</div>

          <div class="affair-status-container">
            <div class="affair-status" v-if="affair.status">
              <font-awesome-icon
                  :icon="$store.getters.getAffairStatusList[affair.status].icon"
                  class="affair-status-icon"
              />
              <span>
                                {{ $store.getters.getAffairStatusList[affair.status].label }}
                            </span>
            </div>
            <BaseButton
                @click="openChangeAffairStatusModal"
                buttonClass="grey-button"
                title="Changer le statut"
                v-if="affair.status !== affairStatusEnum.PENDING_ADMIN_VALIDATION && !$store.getters.isTechnicianManager"
            >
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']"/>
              </template>
            </BaseButton>
          </div>

          <BaseButton @click="getMessagesAndOpenModal(affair.id)" buttonClass="green-button"
                      buttonText="Fil de discussion" title="Accéder au fil de discussion">
            <template v-slot:iconBefore>
              <font-awesome-icon :icon="['fas', 'comment-check']"/>
            </template>
          </BaseButton>
        </template>

        <template v-slot:modalTabs>
          <div class="spindle-tab" data-tab-name="spindle">
            <form>
              <div id="photo-section" v-if="affair.repairType?.value !== repairTypeEnum.FOUR">
                <div>
                  <div class="photo-topbar">
                    <div class="photo-topbar-title">Photos</div>
                    <div v-show="(affair.spindlePhotos?.length || 0) < 3 && !$store.getters.isTechnician">
                      <input class="take-new-pictures-btn" id="take-new-pictures-btn-1" :type="$store.getters.isFromAppTablet ? 'button' : 'file'"
                        accept="image/png, image/gif, image/jpeg" capture @click="tabletChooser(event, 'spindle')" @change="(event) => $store.getters.isFromAppTablet ? '' : takeNewSpindlePicture(event)"/>
                      <label for="take-new-pictures-btn-1">
                        <font-awesome-icon :icon="['fas', 'camera']"/>
                        Prendre une photo
                      </label>
                    </div>
                  </div>
                  <div class="photos" v-if="affair.spindlePhotos?.length > 0">
                    <Photo
                        :displayDeleteButton="false"
                        :image="spindlePhoto"
                        :index="String(index)"
                        v-bind:key="index"
                        v-for="[index, spindlePhoto] of affair.spindlePhotos?.entries()"
                    ></Photo>
                  </div>
                  <div class="no-photos" v-else>
                    Aucune photo
                  </div>
                </div>
                <div>
                  <div class="photo-topbar">
                    <div class="photo-topbar-title">Photos des documents</div>
                    
                    <div v-show="(affair.documentPhotos?.length || 0) < 3  && !$store.getters.isTechnician">
                      <input class="take-new-pictures-btn" id="take-new-pictures-btn-2" :type="$store.getters.isFromAppTablet ? 'button' : 'file'"
                        accept="image/png, image/gif, image/jpeg" capture @click="tabletChooser(event, 'document')" @change="(event) => $store.getters.isFromAppTablet ? '' : takeNewDocumentPicture(event)"/>
                      <label for="take-new-pictures-btn-2">
                        <font-awesome-icon :icon="['fas', 'camera']"/>
                        Prendre une photo
                      </label>
                    </div>
                    
                  </div>
                  <div class="photos" v-if="affair.documentPhotos?.length > 0">
                    <Photo
                        :displayDeleteButton="false"
                        :image="documentPhoto"
                        :index="String(index)"
                        v-bind:key="index"
                        v-for="[index, documentPhoto] of affair.documentPhotos?.entries()"
                    ></Photo>
                  </div>
                    <div class="no-photos" v-else>
                        Aucune photo
                    </div>
                </div>
              </div>
              <div id="input-section">
                <div class="affair-form-row"
                     style="grid-template-columns: repeat(3, 1fr); margin-bottom: 10px; margin-left: 40px; margin-top: 5px;">
                  <BaseCheckbox
                      v-model="affair.internal"
                      label="Affaire interne"
                  />
                  <BaseCheckbox
                      v-model="affair.clockingTaskable"
                      label="Affaire pointable"
                  />
                </div>
                <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
                  <BaseDatepicker
                      :displayError="displayError"
                      :error="formErrors?.receiptDate?.error?.message"
                      :required="true"
                      @onChange="onFormInputChange"
                      :label="[repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM].includes(affair.repairType?.value) ? 'Date de création' : 'Date d\'arrivée'"
                      name="receiptDate"
                      v-model="affair.receiptDate"
                  />
                  <BaseSelect
                      :defaultOptions="establishments"
                      :displayError="displayError"
                      :error="formErrors?.establishment?.error?.message"
                      :required="true"
                      @onChange="onFormInputChange"
                      label="Établissement"
                      name="establishment"
                      v-model="affair.establishment"
                  />
                  <BaseSelect
                      :defaultOptions="repairTypes"
                      :displayError="displayError"
                      :error="formErrors?.repairType?.error?.message"
                      :required="true"
                      @onChange="onFormInputChange"
                      label="Type de prestation"
                      name="repairType"
                      v-model="affair.repairType"
                      :maxHeight="300"
                      :disabled="true"
                  />
                </div>
                <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
                  <BaseSelect
                      :defaultOptions="defaultOptions"
                      :displayError="displayError"
                      :error="
                                        formErrors?.billToCustomerId?.error
                                            ?.message
                                    "
                      :minChars="1"
                      :required="true"
                      :searchable="true"
                      @onButtonActionClick="onAddCustomer"
                      @onButtonSecondActionClick="onEditCustomer"
                      @onChange="onFormInputChange"
                      api="customer/search"
                      fieldLabel="name"
                      fieldValue="id"
                      label="Client facturé"
                      name="billToCustomerId"
                      v-model="affair.billToCustomerId"
                      :class="{'input-to-create': affair.billToCustomerId?.value == -1 }"
                  >
                    <template v-slot:iconAction>
                      <font-awesome-icon :icon="['fas', 'plus']"/>
                    </template>
                    <template v-slot:iconSecondAction>
                      <font-awesome-icon :icon="['fas', 'edit']"/>
                    </template>
                  </BaseSelect>
                  <BaseSelect
                      :defaultOptions="defaultOptions"
                      :displayError="displayError"
                      :error="
                                        formErrors?.shipToCustomerId?.error
                                            ?.message
                                    "
                      :minChars="1"
                      :required="true"
                      :searchable="true"
                      @onButtonActionClick="onAddCustomer"
                      @onButtonSecondActionClick="onEditCustomer"
                      @onChange="onFormInputChange"
                      api="customer/search"
                      fieldLabel="name"
                      fieldValue="id"
                      label="Client final"
                      name="shipToCustomerId"
                      v-model="affair.shipToCustomerId"
                      :class="{'input-to-create': affair.shipToCustomerId?.value == -1 }"
                  >
                    <template v-slot:iconAction>
                      <font-awesome-icon :icon="['fas', 'plus']"/>
                    </template>
                    <template v-slot:iconSecondAction>
                      <font-awesome-icon :icon="['fas', 'edit']"/>
                    </template>
                  </BaseSelect>
                  <RadioButtonContainer
                      v-show="repairCondition.includes(affair.repairType?.value)"
                      :displayError="displayError"
                      :error="
                                        formErrors?.packaging?.error?.message
                                    "
                      :required="repairCondition.includes(affair.repairType?.value)"
                      @onChange="onFormInputChange"
                      label="Conditionnement (caisse)"
                      name="packaging"
                      v-model="affair.packaging"
                  >
                    <BaseRadioButton label="À réparer" name="packaging" v-model="affair.packaging"
                                     value="repair"></BaseRadioButton>
                    <BaseRadioButton label="À conserver" name="packaging" v-model="affair.packaging"
                                     value="keep"></BaseRadioButton>
                    <BaseRadioButton label="À remplacer" name="packaging" v-model="affair.packaging"
                                     value="replace"></BaseRadioButton>
                  </RadioButtonContainer>
                </div>
                <div
                    v-if="[RepairTypeEnum.FOUR, RepairTypeEnum.REEA, RepairTypeEnum.ISSM, RepairTypeEnum.MAIM].includes(affair.repairType?.value)"
                    class="affair-form-row" style="grid-template-columns: repeat(4, 1fr); margin-bottom: 13px">
                  <BaseInput
                      v-model="affair.label"
                      label="Libellé"
                      type="textarea"
                      name="label"
                      :error="formErrors?.label?.error?.message"
                      @onChange="onFormInputChange"
                      :displayError="displayError"
                      :required="repairTypesFields?.label?.required.includes(affair.repairType?.value)"
                      v-if="repairTypesFields?.label?.show.includes(affair.repairType?.value)"
                      style="min-height: 118px; max-height: 200px; grid-column: 1 / 3; width: 97.5%;"
                  />
                  <div
                      style="display: grid; grid-template-rows: repeat(2, 1fr); gap: 10px; grid-column: 3 / 5; width: 100%;">
                    <BaseInput
                        v-model="affair.machine"
                        label="Machine"
                        name="machine"
                        :error="formErrors?.machine?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :required="repairTypesFields?.machine?.required.includes(affair.repairType?.value)"
                        v-if="repairTypesFields?.machine?.show.includes(affair.repairType?.value)"
                    />
                    <BaseSelect
                        v-model="affair.machineMaintenance"
                        label="Maintenance machine"
                        :defaultOptions="defaultMachineMaintenanceOptions"
                        name="machineMaintenance"
                        :required="repairTypesFields?.machineMaintenance?.required.includes(affair.repairType?.value)"
                        v-if="repairTypesFields?.machineMaintenance?.show.includes(affair.repairType?.value)"
                        :error="formErrors?.machineMaintenance?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                  </div>
                </div>
                <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
                  <BaseSelect
                      :defaultOptions="defaultOptions"
                      :displayError="displayError"
                      :error="
                                        formErrors?.spindleBrandId?.error
                                            ?.message
                                    "
                      :searchable="true"
                      @onButtonActionClick="onAddSpindleBrand"
                      @onChange="onFormInputChange"
                      api="spindleBrand/search"
                      fieldLabel="name"
                      fieldValue="id"
                      label="Marque"
                      name="spindleBrandId"
                      v-model="affair.spindleBrandId"
                      :class="{'input-to-create': (affair.spindleBrandId?.value == -1 || affair.spindleBrandId?.value == null ) && affair.repairType?.value !== RepairTypeEnum.ISSB }"
                      :required="false"
                      v-if="repairTypesFields?.spindleBrandId?.show.includes(affair.repairType?.value)"
                  >
                    <template v-slot:iconAction>
                      <font-awesome-icon :icon="['fas', 'plus']"/>
                    </template>
                  </BaseSelect>
                  <BaseSelect
                      :apiParams="{
                                        spindleBrandId:
                                            affair.spindleBrandId?.value,
                                    }"
                      :defaultOptions="defaultOptions"
                      :displayError="displayError"
                      :error="
                                        formErrors?.spindleTypeId?.error
                                            ?.message
                                    "
                      :required="false"
                      v-if="repairTypesFields?.spindleTypeId?.show.includes(affair.repairType?.value)"
                      :searchable="true"
                      @onButtonActionClick="onAddSpindleType"
                      @onChange="onFormInputChange"
                      api="spindleType/search"
                      fieldLabel="name"
                      fieldValue="id"
                      label="Type"
                      name="spindleTypeId"
                      v-model="affair.spindleTypeId"
                      :class="{'input-to-create': (affair.spindleTypeId?.value == -1 || affair.spindleTypeId?.value == null) && affair.repairType?.value !== RepairTypeEnum.ISSB }"
                  >
                    <template v-slot:iconAction>
                      <font-awesome-icon :icon="['fas', 'plus']"/>
                    </template>
                  </BaseSelect>
                  <RadioButtonContainer v-if="repairTypesFields?.model?.show.includes(affair.repairType?.value)"
                                        :displayError="displayError" :error="formErrors?.model?.error?.message"
                                        :required="repairTypesFields?.model?.required.includes(affair.repairType?.value)"
                                        @onChange="onFormInputChange" label="Modèle de broche" name="model"
                                        v-model="affair.model">
                    <BaseRadioButton :disabled="modelRadioButtonDisabled" label="Mécanique" name="model"
                                     v-model="affair.model" value="mechanical"></BaseRadioButton>
                    <BaseRadioButton :disabled="modelRadioButtonDisabled" label="Électrobroche" name="model"
                                     v-model="affair.model" value="electrospindle"></BaseRadioButton>
                    <BaseRadioButton :disabled="modelRadioButtonDisabled" label="Autre" name="model"
                                     v-model="affair.model" value="other"></BaseRadioButton>
                  </RadioButtonContainer>
                </div>
                <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
                  <BaseSearchingInput
                      :apiParams="{
                                            spindleTypeId:
                                                affair.spindleTypeId?.value,
                                            spindleBrandId:
                                                affair.spindleBrandId?.value,
                                        }"
                      @onChange="onFormInputChange"
                      api="spindle/search"
                      fieldId="id"
                      fieldValue="serialNumber"
                      label="Numéro de série"
                      name="serialNumber"
                      type="text"
                      v-model="affair.serialNumber"
                      v-if="repairTypesFields?.serialNumber?.show.includes(affair.repairType?.value)"
                  />
                  <BaseInput
                      :displayError="displayError"
                      :error="formErrors?.weight?.error?.message"
                      @onChange="onFormInputChange"
                      label="Poids (en kg)"
                      name="weight"
                      type="number"
                      v-model="affair.weight"
                      validator="number"
                      :required="repairTypesFields?.weight?.required.includes(affair.repairType?.value)"
                      v-if="repairTypesFields?.weight?.show.includes(affair.repairType?.value)"
                  />
                  <BaseInput
                      label="Éléments joints"
                      name="elements"
                      v-model="affair.elements"
                  />
                </div>
                <div class="affair-form-row" style="grid-template-columns: 0.6fr 0.6fr 1.5fr;"
                     :style="[affair.customsTransit === 'false' ? {'margin-bottom': '13px'} : {'margin-bottom': '4px'}]">
                  <RadioButtonContainer
                      :displayError="displayError"
                      :error="
                                        formErrors?.customsTransit?.error
                                            ?.message
                                    "
                      :required="true"
                      @onChange="onFormInputChange"
                      label="Joindre document douane"
                      name="customsTransit"
                      v-model="affair.customsTransit"
                      v-if="repairTypesFields?.customsTransit?.show.includes(affair.repairType?.value)"
                  >
                    <BaseRadioButton label="Oui" name="customs" v-model="affair.customsTransit"
                                     value="true"></BaseRadioButton>
                    <BaseRadioButton label="Non" name="customs" v-model="affair.customsTransit"
                                     value="false"></BaseRadioButton>
                  </RadioButtonContainer>
                  <RadioButtonContainer
                      v-if="repairTypesFields?.guarantee?.show.includes(affair.repairType?.value)"
                      :displayError="displayError"
                      :error="
                                        formErrors?.guarantee?.error?.message
                                    "
                      :required="true"
                      @onChange="onFormInputChange"
                      label="Demande de prise sous garantie"
                      name="guarantee"
                      v-model="affair.guarantee"
                  >
                    <BaseRadioButton label="Oui" name="guarantee" v-model="affair.guarantee"
                                     value="true"></BaseRadioButton>
                    <BaseRadioButton label="Non" name="guarantee" v-model="affair.guarantee"
                                     value="false"></BaseRadioButton>
                  </RadioButtonContainer>
                  <BaseSelect
                      v-show="affair.guarantee === 'true'"
                      v-model="affair.guaranteeStatus"
                      :default-options="guaranteeStatusOptions"
                      label="Statut garantie"
                      name="guaranteeStatus"
                      :required="affair.guarantee === 'true'"
                      :displayError="displayError"
                      :error="formErrors?.guaranteeStatus?.error?.message"
                      @onChange="onFormInputChange"
                  ></BaseSelect>
                </div>
                <div class="affair-form-row" v-show="affair.customsTransit === 'true'"
                     style="grid-template-columns: repeat(4, 1fr); margin-bottom: 13px">
                  <BaseDatepicker
                      :displayError="displayError"
                      :error="
                                        formErrors?.customsTransitDate?.error
                                            ?.message
                                    "
                      :required="affair.customsTransit === 'true'"
                      @onChange="onFormInputChange"
                      label="Date de passage en douane"
                      name="customsTransitDate"
                      v-model="affair.customsTransitDate"
                  />
                  <BaseInput
                      :displayError="displayError"
                      :error="
                                        formErrors?.customsImaNumber?.error
                                            ?.message
                                    "
                      :required="affair.customsTransit === 'true'"
                      @onChange="onFormInputChange"
                      label="Numéro IMA"
                      name="customsImaNumber"
                      type="text"
                      v-model="affair.customsImaNumber"
                      validator="text"
                  />
                  <BaseInput
                      :displayError="displayError"
                      :error="
                                        formErrors?.customsExaNumber?.error
                                            ?.message
                                    "
                      :required="affair.customsTransit === 'true'"
                      @onChange="onFormInputChange"
                      label="Numéro EXA"
                      name="customsExaNumber"
                      type="text"
                      v-model="affair.customsExaNumber"
                      validator="text"
                  />
                  <BaseInput
                      :displayError="displayError"
                      :error="
                                        formErrors?.customsValue?.error?.message
                                    "
                      :required="affair.customsTransit === 'true'"
                      @onChange="onFormInputChange"
                      label="Valeur douane"
                      name="customsValue"
                      type="text"
                      v-model="affair.customsValue"
                      validator="price"
                  />
                </div>
                <div class="affair-form-row" style="grid-template-columns: repeat(4, 1fr); margin-bottom: 13px"
                     v-if="affair.insuranceStatus">
                  <BaseSelect
                      v-model="affair.insuranceStatus"
                      label="Statut d'assurance"
                      name="insuranceStatus"
                      :disabled="true"
                      :required="true"
                  />
                  <BaseDatepicker
                      v-show="affair.insuranceStatus?.value === 'accepted'"
                      v-model="affair.insuranceStartDate"
                      label="Date de début"
                      name="insuranceStartDate"
                      :disabled="true"
                      :required="affair.insuranceStatus?.value === 'accepted'"
                  />
                  <BaseDatepicker
                      v-show="affair.insuranceStatus?.value === 'accepted'"
                      v-model="affair.insuranceEndDate"
                      label="Date de fin"
                      name="insuranceEndDate"
                      :disabled="true"
                      :required="affair.insuranceStatus?.value === 'accepted'"
                  />
                  <BaseInput
                      v-show="affair.insuranceStatus?.value === 'accepted'"
                      v-model="affair.insuranceAmount"
                      label="Montant d'assurance"
                      type="text"
                      name="insuranceAmount"
                      validator="price"
                      :disabled="true"
                      :required="affair.insuranceStatus?.value === 'accepted'"
                  />
                </div>
                <div class="textareas" style="display: grid; grid-template-columns: repeat(2, 1fr); width: 100%;">
                  <BaseInput
                      v-model="affair.notes"
                      label="Notes (premières informations du fil de discussion)"
                      type="textarea"
                      name="notes"
                      :required="repairTypesFields?.notes?.required.includes(affair.repairType?.value)"
                      v-if="repairTypesFields?.notes?.show.includes(affair.repairType?.value)"
                  />
                  <BaseInput v-if="repairTypesFields?.guarantee?.show.includes(affair.repairType?.value)"
                             label="Informations client à reprendre" name="customerInformations" type="textarea"
                             v-model="affair.customerInformations"/>
                </div>
              </div>
            </form>

            <div class="form-buttons">
              <BaseButton v-if="!$store.getters.isTechnicianManager && !$store.getters.isTechnician" @click="updateAffair"
                          buttonText="Enregistrer les modifications" class="orange-button">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'check']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div
              class="expert-assessment-planning-tab"
              data-tab-name="expertAssessmentPlanning"
              v-if="
                            affair.status !==
                            affairStatusEnum.PENDING_ADMIN_VALIDATION
                        "
          >
            <div
                :set="
                                (expertAssessmentTask =
                                    affair.planningTasks?.find(
                                        (task) => task.discipline.id === -1
                                    ))
                            "
                class="text-container"
            >
              <div v-if="expertAssessmentTask">
                Expertise assignée à
                <span class="expert-assessment-technician-name">
                                    {{
                    expertAssessmentTask.account.name
                  }}
                                </span>
                <span class="expert-assessment-date">
                                    {{
                    format(
                        parse(
                            expertAssessmentTask.date,
                            "yyyy-MM-dd",
                            new Date()
                        ),
                        "'Le' EEEE d MMMM yyyy",
                        {locale: fr}
                    )
                  }}
                                </span>
              </div>
              <div v-else>{{ (["issm", "issb"].includes(affair?.repairType?.value) ? "Intervention" : "Expertise") }}
                non assignée
              </div>
            </div>
            <BaseButton @click="goToPlanning" buttonText="Accéder au planning" class="orange-button">
              <template v-slot:iconAfter>
                <font-awesome-icon :icon="['fas', 'arrow-right']"/>
              </template>
            </BaseButton>
          </div>

          <div class="expert-assessment-tab" data-tab-name="expertAssessment"
               v-if="affair.nomenclature && (affair.expertAssessment?.validated || $store.getters.isAdmin || $store.getters.isTechnicianManager)">
            <BaseSelect
                :defaultOptions="[
                                    { label: 'EBC', value: 'ebc' },
                                    { label: 'Kessler', value: 'kessler' },
                                    { label: 'Light', value: 'light' }
                                ]"
                :required="true"
                label="Choisissez le type de rapport"
                name="eaType"
                v-model="eaType"
            />
            <BaseButton @click="openExpertAssessmentModal" :buttonText="`Consulter / Modifier l'expertise`"
                        class="orange-button">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'edit']"/>
              </template>
            </BaseButton>
            <div id="download-expert-assessment-div" v-if="affair.expertAssessment?.validated">
              <BaseSelect
                  :defaultOptions="[
                                    { label: 'EBC', value: 'ebc' },
                                    { label: 'Kessler', value: 'kessler' },
                                    { label: 'Light', value: 'light' }
                                ]"
                  :required="true"
                  label="Choisissez le type de PDF"
                  name="eaPDFType"
                  v-model="eaPDFType"
              />
              <BaseButton @click="downloadExpertAssessmentPDF" buttonText="Télécharger le PDF" class="blue-button">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'file-pdf']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div class="expert-assessment-tab" data-tab-name="interventionReport">
            <BaseButton @click="openInterventionReportModal"
                        :buttonText="`Consulter / Modifier le rapport d'intervention`" class="orange-button">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'edit']"/>
              </template>
            </BaseButton>
            <div id="download-intervention-report-div">
              <BaseButton @click="downloadInterventionReportPDF" buttonText="Télécharger le PDF" class="blue-button">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'file-pdf']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div
              data-tab-name="nomenclature"
              v-if="
                            ![
                                affairStatusEnum.PENDING_ADMIN_VALIDATION,
                                /*affairStatusEnum.PENDING_EXPERT_ASSESSMENT_PLANNING,
                                affairStatusEnum.ONGOING_EXPERT_ASSESSMENT,*/
                            ].includes(affair.status)
                        "
          >
            <form class="form-column" id="form-nomenclature" v-if="affair.nomenclature">
              <div>
                <div id="nomenclature-button-document-container">
                  <div>
                    <BaseButton @click="openDocumentation" buttonText="Docum. tech." class="blue-button">
                      <template v-slot:iconBefore>
                        <font-awesome-icon
                            :icon="[
                                                        'fas',
                                                        'folder-download',
                                                    ]"
                        />
                      </template>
                    </BaseButton>
                  </div>
                  <div>
                    <BaseButton @click="openNomenclatureLink" buttonText="Nomenc." class="blue-button">
                      <template v-slot:iconBefore>
                        <font-awesome-icon
                            :icon="['fas', 'file-search']"
                        />
                      </template>
                    </BaseButton>
                  </div>
                  <div v-if="$store.getters.isAdmin || $store.getters.isWorkshopManager">
                    <BaseButton @click="openAnnex" buttonText="Annexe" class="blue-button">
                      <template v-slot:iconBefore>
                        <font-awesome-icon
                            :icon="['fas', 'file-search']"
                        />
                      </template>
                    </BaseButton>
                  </div>
                </div>
                <BaseInput :disabled="!isNomenclatureEditable" :required="false"
                           @onChange="onNomenclatureFormInputChange" label="Éléments à ne pas oublier"
                           name="nomenclatureComment" type="textarea" v-model="affair.nomenclature.comment"/>
                <BaseSelect
                    :disabled="!isNomenclatureEditable"
                    :minChars="1"
                    :searchable="true"
                    @onChange="onDisciplineAdd"
                    api="discipline/search"
                    fieldLabel="label"
                    fieldValue="id"
                    label="Ajouter une discipline"
                    name="searchDiscipline"
                    v-model="form.disciplineCurrent"
                />
                <ListWithDelete :disabled="!isNomenclatureEditable" label="Liste des disciplines"
                                v-model="affair.nomenclature.disciplines">
                  <template v-slot:content="{ item }">
                    <div>{{ item.label }}</div>
                  </template>
                </ListWithDelete>
              </div>
              <div>
                <div id="nomenclature-container-add-article">
                  <div id="nomenclature-container-add-article-selectors">
                    <BaseSelect
                        v-model="form.articlesEstablishmentFilter"
                        label="Etablissement"
                        :defaultOptions="establishments"
                        name="searchArticleEstablishment"
                        :display-tag="true"
                        :disabled="!isNomenclatureEditable"
                        fieldLabel="label"
                        fieldValue="id"
                    />
                    <BaseSelect
                        :disabled="!isNomenclatureEditable"
                        :searchable="true"
                        @onChange="onArticleAdd"
                        api="article/search"
                        :apiParams="{
                            withSupplier: true,
                            establishment: form.articlesEstablishmentFilter?.value
                        }"
                        fieldLabel="label"
                        fieldValue="id"
                        label="Ajouter un article"
                        name="searchArticle"
                        v-if="isNomenclatureEditable"
                        v-model="form.articleCurrent"
                        :maxHeight="500"
                    />
                  </div>

                  <div id="nomenclature-container-add-article-buttons">
                    <div>
                      <BaseButton
                        v-if="!$store.getters.isTechnician"
                        @click="createArticle"
                        buttonText="Créer un article"
                        class="blue-button">
                        <template v-slot:iconBefore>
                          <font-awesome-icon
                              :icon="[
                                  'fas',
                                  'plus',
                              ]"
                          />
                        </template>
                      </BaseButton>
                      <BaseButton
                          @click="downloadNomenclature"
                          buttonText="Télécharger la nomenclature"
                          class="blue-button">
                        <template v-slot:iconBefore>
                          <font-awesome-icon
                              :icon="[
                                  'fas',
                                  'file-pdf',
                              ]"
                          />
                        </template>
                      </BaseButton>
                    </div>
                  </div>
                </div>
                <div class="container-groups-family-articles">
                  <div v-for="(groupByFamily, index) in affair.nomenclature.articlesGroupByFamily" :key="index">
                    <ListWithDelete
                        :disabled="!isNomenclatureEditable || $store.getters.isTechnician"
                        :label="groupByFamily.family"
                        @onDelete="handleRemoveArticle"
                        class="articles-group"
                        v-if="groupByFamily.articles.length > 0"
                        v-model="groupByFamily.articles"
                    >
                      <template v-slot:content="{ item, index }">
                        <div class="article-detail-container">
                          <div
                              :style="(item.outOfNomenclatureType !== undefined && !item.outOfNomenclatureType ? 'font-weight: bold;' : '')">
                            {{ item.label }}
                          </div>
                          <BaseInput
                              :disabled="!isNomenclatureEditable"
                              :displayError="displayError"
                              :error="formErrors[
                                'quantity_' + index]?.error?.message"
                              :isSmall="true"
                              :label="'Qté' + (item.unit ? ` (${item.unit})` : '') + '(' + item.currentQuantity + ')'"
                              :name="'quantity_' + index"
                              @onChange="onFormInputChange"
                              type="text"
                              v-model="item.quantity"
                              validator="decimal_2"
                          />
                          <div>
                            <div class="article-stock-detail" v-if="item.handleOutOfStock">
                              Stock
                              <div>
                                <font-awesome-icon
                                    :icon="[
                                      'fas',
                                      'check-circle'
                                    ]"
                                    class="article-stock-check"
                                    v-if="parseFloat(item.quantity) <= parseFloat(item.currentQuantity)"
                                />
                                {{
                                  item.currentQuantity
                                }}
                              </div>
                            </div>
                            <div
                              class="article-stock-detail"
                              v-if="item.handleOutOfStock && parseFloat(item.quantity) > parseFloat(item.currentQuantity)"
                            >
                              <div>Manque</div>
                              <div>
                                {{
                                  parseFloat(item.quantity) -
                                  parseFloat(item.currentQuantity)
                                }}
                              </div>
                            </div>
                          </div>
                          <BaseSelect
                              :disabled="!isNomenclatureEditable"
                              v-if="!isValidArticle(item.value) && !$store.getters.isTechnician"
                              :displayTag="true"
                              :isSmall="true"
                              :minChars="1"
                              :multiple="true"
                              :searchable="true"
                              api="supplier/search"
                              fieldLabel="name"
                              fieldValue="id"
                              label="Fournisseurs"
                              placeholder="Rechercher"
                              v-model="item.suppliers"
                          />
                          <div v-else></div>
                          <BaseButton @click="addArticleToNomenclatureType" buttonText=""
                                      title="Mettre à jour dans la nomenclature type" class="green-button"
                                      v-if="isNomenclatureEditable && !$store.getters.isTechnician" :data-articleId="item.value"
                                      :data-quantity="item.quantity">
                            <template v-slot:iconBefore>
                              <font-awesome-icon :icon="['fas', 'sync']"/>
                            </template>
                          </BaseButton>
                        </div>
                      </template>
                    </ListWithDelete>
                  </div>
                </div>
              </div>
            </form>

            <div id="nomenclature-not-completed" v-if="!affair?.nomenclature">
              <div class="text-container">L'agent n'a pas encore complété la nomenclature</div>
            </div>

            <div class="form-buttons" v-if="affair.nomenclature && !$store.getters.isTechnician">
              <BaseButton :disabled="!isNomenclatureEditable" @click="updateNomenclature(false)"
                          buttonText="Enregistrer la nomenclature" class="blue-button">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'check']"/>
                </template>
              </BaseButton>
              <BaseButton :disabled="!isNomenclatureEditable" @click="updateNomenclature(true)"
                          buttonText="Enregistrer la nomenclature et créer les demandes de prix" class="orange-button">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'check']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="quotation" class="order-document-tab">
            <div v-if="(!quotations || quotations.length === 0)">
              <div id="quotation-not-available"
                   v-if="isCurrentStatusBefore($store, affair.status, affairStatusEnum.PENDING_QUOTATION)">
                <div class="text-container">Merci de compléter les étapes précédentes pour créer le devis</div>
              </div>
              <div v-else>
                <BaseButton @click="openOrderDocumentModal('quotation')" buttonText="Créer le devis"
                            class="orange-button">
                  <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                  </template>
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <div v-if="quotations[0].status === 'validated' || quotations[0].status === 'refused'">
                <div class="text-container">
                                    <span id="quotation-invoice-status-text">
                                        Devis
                                        {{ quotations[0].status === "validated" ? "validé" : "refusé" }}
                                    </span>
                  <div id="quotation-invoice-status-date">
                    {{
                      quotations[0].statusDate !== null ? format(parse(quotations[0].statusDate, "dd/MM/yyyy HH:mm", new Date()), "'Le' EEEE d MMMM yyyy", {locale: fr}) : ""
                    }}
                  </div>
                </div>
              </div>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  :buttonText="'Consulter' + (quotations[0].status !== 'validated' ? ' / Modifier' : '') + ' le devis'"
                  @click="openOrderDocumentModal('quotation')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'edit']"/>
                </template>
              </BaseButton>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Créer un nouveau devis"
                  @click="createNewOrderDocument('quotation')"
                  data-mode="quotation"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="arOrder" class="order-document-tab">
            <div v-if="(!arOrders || arOrders.length === 0)">
              <div id="ar-order-not-available"
                   v-if="isCurrentStatusBefore($store, affair.status, affairStatusEnum.PENDING_QUOTATION)">
                <div class="text-container">Merci de compléter les étapes précédentes pour créer l'AR de commande</div>
              </div>
              <div v-else>
                <BaseButton 
                  v-if="!$store.getters.isTechnician"
                  @click="openOrderDocumentModal('arOrder')" buttonText="Créer l'AR de commande"
                  class="orange-button">
                  <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                  </template>
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Consulter / Modifier l'AR de commande"
                  @click="openOrderDocumentModal('arOrder')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'edit']"/>
                </template>
              </BaseButton>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Créer un nouveau AR de commande"
                  @click="createNewOrderDocument('arOrder')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="proForma" class="order-document-tab">
            <div v-if="(!proFormas || proFormas.length === 0)">
              <div id="pro-forma-not-available"
                   v-if="isCurrentStatusBefore($store, affair.status, affairStatusEnum.PENDING_QUOTATION)">
                <div class="text-container">Merci de compléter les étapes précédentes pour créer la facture Pro Forma
                </div>
              </div>
              <div v-else>
                <BaseButton
                  v-if="!$store.getters.isTechnician"
                  @click="openOrderDocumentModal('proForma')"
                  buttonText="Créer la facture Pro Forma"
                            class="orange-button">
                  <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                  </template>
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <BaseButton
                  buttonText="Consulter / Modifier la facture Pro Forma"
                  @click="openOrderDocumentModal('proForma')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'edit']"/>
                </template>
              </BaseButton>
              <BaseButton
                  buttonText="Créer une nouvelle facture Pro Forma"
                  @click="createNewOrderDocument('proForma')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="repair" id="repair-tab">
            <form>
              <div style="display: block" v-if="!affair.noRepair">
                <table id="table-state">
                  <thead>
                  <tr>
                    <th>Date de la commande</th>
                    <th>Commande client</th>
                    <th>AR réalisé</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{ affair?.arOrderDate ?? '-' }}
                    </td>
                    <td>
                      <font-awesome-icon
                          :icon="['fas', 'check']"
                          class="repair-icon-ok"
                          v-if="affair?.orderDocumentsCount?.arOrder > 0"
                      />
                      <font-awesome-icon
                          :icon="['fas', 'times']"
                          class="repair-icon-ko"
                          v-if="!(affair?.orderDocumentsCount?.arOrder > 0)"
                      />
                    </td>
                    <td>
                      <font-awesome-icon
                          :icon="['fas', 'check']"
                          class="repair-icon-ok"
                          v-if="affair?.onGoingOrder === 0"
                      />
                      <font-awesome-icon
                          :icon="['fas', 'times']"
                          class="repair-icon-ko"
                          v-if="affair?.onGoingOrder !== 0"
                      />
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!--<div id="repair-line-article-linked-container" v-if="affair?.articlesLinked?.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th>Article</th>
                                <th>Quantité</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(article, index) in affair.articlesLinked">
                                <td>{{ article.label }}</td>
                                <td>{{ article.quantity }}</td>
                                <td>{{ article.type === 'order' ? 'Commande' : 'Réception' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>-->
                <div id="repair-line-article-container" v-if="affair?.articlesStock?.length > 0">
                  <table>
                    <thead>
                    <tr>
                      <th>Article</th>
                      <th>Qté</th>
                      <th>Retiré</th>
                      <th>à retirer</th>
                      <th>Stock</th>
                      <th>Manque</th>
                      <th>État</th>
                      <th>Qté à retirer</th>
                      <th>Retrait des stocks</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(article, index) in affair.articlesStock">
                            <td><b>{{ article.kitLabel ? article.kitLabel + ' : ' : '' }}</b>{{ article.label }}</td>
                            <td>{{ article.quantity }}</td>
                            <td>{{ article.quantityAlreadyWithdrawal }}</td>
                            <td>
                                <font-awesome-icon
                                    :icon="['fas', 'check-circle']"
                                    class="repair-icon-ok"
                                    v-if="(article.quantity - article.quantityAlreadyWithdrawal) <= 0"
                                />
                                <span v-if="(article.quantity - article.quantityAlreadyWithdrawal) > 0">{{ article.quantity - article.quantityAlreadyWithdrawal }}</span></td>
                            <td>{{ article.currentQuantity }}</td>
                            <td>
                                {{
                                    (article.quantity - article.quantityAlreadyWithdrawal) > article.currentQuantity ? Math.abs(article.currentQuantity - (article.quantity - article.quantityAlreadyWithdrawal)) : ""
                                }}
                            </td>
                            <td>
                                <font-awesome-icon
                                    :icon="['fas', 'check-circle']"
                                    class="repair-icon-ok"
                                    v-if="(article.quantity - article.quantityAlreadyWithdrawal) <= article.currentQuantity"
                                />
                                <font-awesome-icon
                                    :icon="['fas', 'times-circle']"
                                    class="repair-icon-ko"
                                    v-if="(article.quantity - article.quantityAlreadyWithdrawal) > article.currentQuantity"
                                />
                            </td>
                            <td>
                                <BaseInput
                                    label=""
                                    type="text"
                                    v-model="affair.articlesStock[index].quantityToWithdrawal"
                                    validator="decimal_2"
                                />
                            </td>
                            <td>
                                <BaseDatepicker
                                    :disabled="!(affair?.orderDocumentsCount?.arOrder > 0)"
                                    :displayTime="true"
                                    name="withdrawalDate"
                                    v-if="(article.quantity - article.quantityAlreadyWithdrawal) <= article.currentQuantity"
                                    v-model="affair.articlesStock[index].currentStockWithdrawalDate"
                                />
                            </td>
                            <td>
                                <BaseButton
                                    @click="withdrawArticle(index)"
                                    buttonText="Retirer du stock"
                                    class="blue-button"
                                    v-if="(article.quantity - article.quantityAlreadyWithdrawal) <= article.currentQuantity && affair?.orderDocumentsCount?.arOrder > 0"
                                >
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon
                                            :icon="['fas', 'minus-square']"
                                        />
                                    </template>
                                </BaseButton>
                            </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="no-reparation" v-if="affair.noRepair">
                <div class="text-container">
                  <div style="font-weight: bold; margin-bottom: 10px;">Aucune réparation à effectuer</div>
                  Cette broche ne nécessite pas d'être réparée
                  ({{ $store.getters.getAffairStatusList[affair.status].label }})
                </div>
              </div>
            </form>
            <div class="form-buttons" v-if="!affair.noRepair">
              <BaseButton
                  :disabled="(affair?.status !== affairStatusEnum.PENDING_REPAIRS && affair?.status !== affairStatusEnum.ONGOING_REPAIRS) || affair?.onGoingOrder !== 0 || !(affair?.orderDocumentsCount?.arOrder > 0) || affair.articlesStock?.filter(article => article.quantity > article.quantityAlreadyWithdrawal).length > 0"
                  @click="authorizeRepair"
                  buttonText="Terminer le retrait des stocks"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'check']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="conformityReport" id="conformity-report-tab">
            <div id="conformity-report-not-available"
                 v-if="affair && !affair.noRepair && (affair.status !== affairStatusEnum.PENDING_TESTS && affair.conformityReport !== null) && (affair.status !== affairStatusEnum.ONGOING_TESTS && affair.conformityReport !== null) && !affair?.conformityReport?.validated">
              <div class="text-container" v-if="affair.status !== affairStatusEnum.PENDING_TESTS_PLANNING">Merci de
                compléter les étapes précédentes avant de réaliser le PV de conformité
              </div>
              <div v-else>
                <div class="text-container">En attente de planification des tests</div>
                <BaseButton
                  v-if="!$store.getters.isTechnician"
                  @click="goToPlanning"
                  buttonText="Accéder au planning"
                  class="orange-button"
                  style="margin-left: auto; margin-right: auto;">
                  <template v-slot:iconAfter>
                    <font-awesome-icon :icon="['fas', 'arrow-right']"/>
                  </template>
                </BaseButton>
              </div>
            </div>

            <BaseButton
                @click="openConformityReportModal"
                buttonText="Consulter / Modifier le PV de conformité"
                class="orange-button"
                v-if="!$store.getters.isTechnician && (affair.noRepair || (affair.status === affairStatusEnum.PENDING_TESTS && affair.conformityReport !== null) || (affair.status === affairStatusEnum.ONGOING_TESTS && affair.conformityReport !== null) || affair?.conformityReport?.validated)"
            >
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'edit']"/>
              </template>
            </BaseButton>
            <BaseButton @click="downloadConformityReportPDF" buttonText="Télécharger le PV de conformité"
                        class="blue-button" v-if="affair?.conformityReport?.validated">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']"/>
              </template>
            </BaseButton>
            <BaseButton @click="downloadConformityReportCheckListPDF" buttonText="Télécharger la checklist"
                        class="blue-button" v-if="affair?.conformityReport?.validated">
              <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']"/>
              </template>
            </BaseButton>
          </div>

          <div data-tab-name="deliveryForm" class="order-document-tab">
            <div v-if="(!deliveryForms || deliveryForms.length === 0)">
              <div>
                <BaseButton
                  v-if="!$store.getters.isTechnician"
                  @click="openOrderDocumentModal('deliveryForm')"
                  buttonText="Créer le bon de livraison"
                  class="orange-button">
                  <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                  </template>
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Consulter / Modifier le bon de livraison"
                  @click="openOrderDocumentModal('deliveryForm')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'edit']"/>
                </template>
              </BaseButton>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Créer un nouveau bon de livraison"
                  @click="createNewOrderDocument('deliveryForm')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="invoice" class="order-document-tab">
            <div v-if="(!invoices || invoices.length === 0)">
              <div id="invoice-not-available"
                   v-if="isCurrentStatusBefore($store, affair.status, affairStatusEnum.TO_BILL)">
                <div class="text-container">Merci de compléter les étapes précédentes pour créer la facture</div>
              </div>
              <div v-else>
                <BaseButton
                  v-if="!$store.getters.isTechnician"
                  @click="openOrderDocumentModal('invoice')"
                  buttonText="Créer la facture"
                  class="orange-button">
                  <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                  </template>
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Consulter / Modifier la facture"
                  @click="openOrderDocumentModal('invoice')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'edit']"/>
                </template>
              </BaseButton>
              <BaseButton
                  v-if="!$store.getters.isTechnician"
                  buttonText="Créer une nouvelle facture"
                  @click="createNewOrderDocument('invoice')"
                  class="orange-button"
              >
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </template>
              </BaseButton>
            </div>
          </div>

          <div data-tab-name="clocking" id="clocking-tab"
               v-if="affair.status !== affairStatusEnum.PENDING_ADMIN_VALIDATION">
            <form>
              <div id="clocking-tab-title">
                {{ affair.affairNumber }} -
                {{ affair.billToCustomer != null ? affair.billToCustomer.name : "Client à déterminer" }}
              </div>
              <div id="clocking-tab-subtitle">
                {{ affair.spindle?.spindleType?.spindleBrand?.name }} {{ affair.spindle?.spindleBrand?.name }}
              </div>
              <div id="clocking-tab-subtitle-2">
                Durée totale : {{ affair.affairDuration || "0H00" }}
              </div>
              <table>
                <thead>
                <tr>
                  <th v-on:click="() => changeAffairClockingTableSorter(0)">Agent&nbsp;<span v-if="affairClockingTableSorter && (affairClockingTableSorter?.columnIndex === 0)"><font-awesome-icon :icon="['fas', affairClockingTableSorter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span></th>
                  <th v-on:click="() => changeAffairClockingTableSorter(1)">Discipline&nbsp;<span v-if="affairClockingTableSorter && (affairClockingTableSorter?.columnIndex === 1)"><font-awesome-icon :icon="['fas', affairClockingTableSorter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span></th>
                  <th v-on:click="() => changeAffairClockingTableSorter(2)">Date de début&nbsp;<span v-if="affairClockingTableSorter && (affairClockingTableSorter?.columnIndex === 2)"><font-awesome-icon :icon="['fas', affairClockingTableSorter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span></th>
                  <th v-on:click="() => changeAffairClockingTableSorter(3)">Date de fin&nbsp;<span v-if="affairClockingTableSorter && (affairClockingTableSorter?.columnIndex === 3)"><font-awesome-icon :icon="['fas', affairClockingTableSorter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span></th>
                  <th v-on:click="() => changeAffairClockingTableSorter(4)">Temps total de pointage&nbsp;<span v-if="affairClockingTableSorter && (affairClockingTableSorter?.columnIndex === 4)"><font-awesome-icon :icon="['fas', affairClockingTableSorter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span></th>
                </tr>
                </thead>
                <tbody>
                <tr :key="index" v-for="(task, index) in affair.affairTasks">
                  <td>{{ task.account.name }}</td>
                  <td>{{ task.discipline.description }}</td>
                  <td>{{ task.startedDate }}</td>
                  <td>{{ task.endedDate }}</td>
                  <td>{{ task.duration }}</td>
                </tr>
                </tbody>
              </table>
            </form>
          </div>

          <div data-tab-name="analytics" id="analytics-tab">
            <form>
              <div id="analytics-tab-title">
                Temps passé sur les différents statuts
              </div>
              <div id="analytics-tab-subtitle">
                {{ affair.affairNumber }}
              </div>
              <table>
                <thead>
                <tr>
                  <th>Statut</th>
                  <th>Temps par statut</th>
                </tr>
                </thead>
                <tbody>
                <tr :key="index" v-for="(status, index) in affair.statusHistory">
                  <td>{{ status.statusLabel }}</td>
                  <td>{{ status.timeByStatus }}</td>
                </tr>
                </tbody>
              </table>
            </form>
          </div>

          <div data-tab-name="docHistory" id="doc-history">
            <div id="doc-history-title">
              <div>
                <font-awesome-icon :icon="['fas', 'arrow-to-bottom']" class="icon icon-times fa-lg"/>
              </div>
              <div>Cliquez sur la version d'un document pour la télécharger</div>
            </div>
            <div id="doc-history-content">
              <div class="doc-history-column" v-if="!(['issb', 'issm'].includes(affair.repairType?.value))">
                <h2>Expertise</h2>
                <div
                    v-if="affair.docHistory.expertAssessment === undefined || affair.docHistory.expertAssessment.length <= 0">
                  <div class="doc-history-item-null">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#ccc" class="fa-2x" :icon="['fas', 'file']"/>
                    </div>
                  </div>
                </div>
                <div v-for="(doc, index) in affair.docHistory.expertAssessment" :key="index">
                  <div @click="openDocFromHistory(doc.path)" class="doc-history-item">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#E33F3F" class="fa-2x" :icon="['fas', 'file-pdf']"/>
                    </div>
                    <div v-if="doc.creationDate !== ''"><p>Version {{ doc.version }} - {{ doc.type }} -
                      {{ new Date(doc.creationDate).toLocaleDateString() }}</p></div>
                    <div v-if="doc.creationDate === ''"><p>Version {{ doc.version }} - {{ doc.type }}</p></div>
                  </div>
                </div>
              </div>
              <div class="doc-history-column" v-if="['issb', 'issm'].includes(affair.repairType?.value)">
                <h2>Intervention</h2>
                <div
                    v-if="affair.docHistory.interventionReport === undefined || affair.docHistory.interventionReport.length <= 0">
                  <div class="doc-history-item-null">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#ccc" class="fa-2x" :icon="['fas', 'file']"/>
                    </div>
                  </div>
                </div>
                <div v-for="(doc, index) in affair.docHistory.interventionReport" :key="index">
                  <div @click="openDocFromHistory(doc.path)" class="doc-history-item">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#E33F3F" class="fa-2x" :icon="['fas', 'file-pdf']"/>
                    </div>
                    <div v-if="doc.creationDate !== ''"><p>Version {{ doc.version }} - {{ new Date(doc.creationDate).toLocaleDateString() }}</p></div>
                    <div v-if="doc.creationDate === ''"><p>Version {{ doc.version }}</p></div>
                  </div>
                </div>
              </div>
              <div class="doc-history-column" v-if="!(['issb', 'issm'].includes(affair.repairType?.value))">
                <h2>PV de conformité</h2>
                <div
                    v-if="affair.docHistory.conformityReport === undefined || affair.docHistory.conformityReport.length <= 0">
                  <div class="doc-history-item-null">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#ccc" class="fa-2x" :icon="['fas', 'file']"/>
                    </div>
                  </div>
                </div>
                <div v-for="(doc, index) in affair.docHistory.conformityReport" :key="index">
                  <div @click="openDocFromHistory(doc.path)" class="doc-history-item">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#E33F3F" class="fa-2x" :icon="['fas', 'file-pdf']"/>
                    </div>
                    <div v-if="doc.creationDate !== ''"><p>Version {{ doc.version }} - {{ new Date(doc.creationDate).toLocaleDateString() }}</p></div>
                    <div v-if="doc.creationDate === ''"><p>Version {{ doc.version }}</p></div>
                  </div>
                </div>
              </div>
              <div class="doc-history-column" v-for="(docType, index) in Object.keys(affair.docHistory.orderDocument)"
                   :key="index">
                <h2>{{ docType }}</h2>
                <div
                    v-if="affair.docHistory.orderDocument?.[docType] === undefined || affair.docHistory.orderDocument?.[docType].length <= 0">
                  <div class="doc-history-item-null">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#ccc" class="fa-2x" :icon="['fas', 'file']"/>
                    </div>
                  </div>
                </div>
                <div v-for="(doc, index) in affair.docHistory.orderDocument?.[docType]" :key="index">
                  <div @click="openDocFromHistory(doc.path)" class="doc-history-item">
                    <div class="doc-history-item-logo">
                      <font-awesome-icon color="#E33F3F" class="fa-2x" :icon="['fas', 'file-pdf']"/>
                    </div>
                    <div v-if="doc.creationDate !== ''"><p>Version {{ doc.version }} - {{ doc.docNumber }} -
                      {{ new Date(doc.creationDate).toLocaleDateString() }}</p></div>
                    <div v-if="doc.creationDate === ''"><p>Version {{ doc.version }} - {{ doc.docNumber }}</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ModalMenu>
    </template>
  </Modal>

  <Modal :hideIcon="true" v-show="displayChangeAffairStatusModal === true">
    <template v-slot:modalTitle>
      <div>MODIFIER LE STATUT DE L'AFFAIRE</div>
    </template>
    <template v-slot:modalContent>
      <form>
        <div>
          <BaseSelect :defaultOptions="manualStatus" label="Nouveau statut" name="newStatus" v-model="newStatus"/>
        </div>
      </form>
    </template>
    <template v-slot:modalButtons>
      <BaseButton v-if="!$store.getters.isTechnician" @click="closeChangeAffairStatusModal" buttonText="Fermer" class="white-button">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
      <BaseButton v-if="!$store.getters.isTechnician" @click="changeAffairStatus" buttonText="Valider" class="orange-button">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>

  <Modal :hideIcon="true" v-show="displayChooseOrderDocumentModelModal === true">
    <template v-slot:modalTitle>
      <div>CRÉER {{ chooseOrderDocumentModelType }}</div>
    </template>
    <template v-slot:modalContent>
      <form>
        <div>
          <BaseSelect :defaultOptions="chooseOrderDocumentModels" label="Document initial" name="quotationChoice"
                      v-model="chooseOrderDocumentModel"/>
        </div>
      </form>
    </template>
    <template v-slot:modalButtons>
      <BaseButton @click="closeChooseOrderDocumentModelModal" buttonText="Fermer" class="white-button">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
      <BaseButton v-if="!$store.getters.isTechnician" @click="validateChooseOrderDocumentModel" buttonText="Valider" class="orange-button">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>

  <ExpertAssessmentModal
      @onExit="closeExpertAssessmentModal"
      @openMessagesModal="openMessagesModal"
      @refresh="refreshAffair"
      @refreshNomenclatureArticles="refreshNomenclatureArticles"
      ref="expertAssessmentModal"
      v-if="!$store.getters.isCustomer"
      v-model="affair.expertAssessment"
      v-show="displayExpertAssessmentModal"
      :eaType="eaType"
  ></ExpertAssessmentModal>

  <InterventionReportModal
      @onExit="closeInterventionReportModal"
      @refresh="refreshAffair"
      ref="interventionReportModal"
      v-if="!$store.getters.isCustomer"
      v-model="affair.interventionReport"
      v-show="displayInterventionReportModal"
  ></InterventionReportModal>

  <ConformityReportModal
      @onExit="closeConformityReportModal"
      @refresh="refreshAffair"
      ref="conformityReportModal"
      v-if="!$store.getters.isCustomer"
      v-model="affair.conformityReport"
      v-show="displayConformityReportModal"
  ></ConformityReportModal>

  <OrderDocumentModal
      v-if="orderDocuments"
      v-model:orderDocuments="orderDocuments"
      v-show="displayOrderDocumentModal"
      :orderDocumentId="orderDocumentId"
      @onClose="closeOrderDocumentModal"
      @onOrderDocumentUpdated="refreshAffair"
  ></OrderDocumentModal>
  <MessagesModal :title="`Fil de discussion de l'affaire`" @close="closeMessagesModal" @onSendMessage="onSendMessage"
                 v-if="!$store.getters.isCustomer" v-model="affair.messages"
                 v-show="displayMessagesModal"></MessagesModal>

  <Modal
      id="quotation_validation_modal"
      v-show="displayQuotationValidationModal"
      @onClose="closeQuotationValidationModal"
      v-bind:hide-icon="true"
  >
    <template v-slot:modalTitle>
      VALIDER/REFUSER LE DEVIS
    </template>
    <template v-slot:modalContent>
      <div id="validation_modal_content">
        <div class="validation_modal_text">Souhaitez-vous valider le devis ?</div>
        <div id="validation_modal_choice">
          <BaseRadioButton
              v-model="quotationResponse"
              label="Accepter"
              value="true"
              name="quotationValidationChoice"
          />
          <BaseRadioButton
              v-model="quotationResponse"
              label="Refuser"
              value="false"
              name="quotationValidationChoice"
          />
          <div class="quotation-infos" v-if="quotationResponse === true || quotationResponse === 'true'">
            <BaseInput
                label="Numéro de commande client"
                v-model="quotationCustomerOrderNumber"
                type="text"
                name="customerOrderNumber"
                :error="quotationValidationFormErrors?.customerOrderNumber?.error?.message"
                @onChange="onQuotationValidationFormInputChange"
                :displayError="displayQuotationValidationError"
                validator="text"
            />
            <ListWithDelete class="documents-list" v-model="quotationDocuments" label="Pièces jointes"
                            @onDelete="handleDocuments">
              <template v-slot:content="{ item }">
                <BaseFile
                    v-model="item.path"
                    name="path"
                    :files="item.file"
                    v-on:update:files="item.file = $event"
                    accept="application/pdf"
                    @onChange="onDocumentChange"
                    :disabled="!item.editable"
                >
                  <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'file-pdf']"/>
                  </template>
                </BaseFile>
              </template>
            </ListWithDelete>
          </div>
          <div class="quotation-infos" v-if="quotationResponse === false || quotationResponse === 'false'">
            <p class="validation_modal_text">
              Merci de préciser la raison de votre refus.
            </p>
            <BaseInput
                v-model="quotationRefusedReason"
                label="Raison du refus"
                type="textarea"
                name="quotationRefusedReason"
                :error="quotationValidationFormErrors?.quotationRefusedReason?.error?.message"
                @onChange="onQuotationValidationFormInputChange"
                :displayError="displayQuotationValidationError"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modalButtons>
      <BaseButton @click="closeQuotationValidationModal" buttonText="Fermer" class="white-button">
      </BaseButton>
      <BaseButton v-if="(quotationResponse === false || quotationResponse === 'false') && !$store.getters.isTechnician" @click="refuseQuotation"
                  buttonText="Refuser" class="grey-button">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'ban']"/>
        </template>
      </BaseButton>
      <BaseButton v-if="(quotationResponse === true || quotationResponse === 'true') && !$store.getters.isTechnician" @click="validateQuotation"
                  buttonText="Valider" class="orange-button">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>

  <NewAffairModal
      v-if="displayCreateAffairModal === true"
      @onCreateAffair="onCreateAffair"
      @onClose="closeCreateAffairModal"
  >
  </NewAffairModal>

  <NewAffairModal
      v-show="displayUpdateNotValidatedAffairModal === true"
      v-model="affair"
      @onCreateAffair="onCreateAffair"
      @onClose="closeUpdateNotValidatedAffairModal"
  >
  </NewAffairModal>

  <Modal v-show="displayLightAffairModal" :hide-icon="true">
    <template v-slot:modalTitle>
      <div>{{ lightAffair?.affairNumber }}</div>
    </template>
    <template v-slot:modalContent>
      <form class="light-affair-grid">
        <div style="margin-bottom: 5px;">
          <BaseInput
              v-model="lightAffair.label"
              label="Libellé"
              type="textarea"
              :disabled="true"
              style="width: 100%; min-height: 75px;"
          />
        </div>
        <div class="light-affair-row">
          <BaseInput
              v-model="lightAffair.repairType"
              label="Type de prestation"
              type="text"
              :disabled="true"
          />
          <RadioButtonContainer
              label="Garantie"
              name="guarantee"
              v-model="lightAffair.guarantee"
          >
            <BaseRadioButton label="Oui" name="guarantee" v-model="lightAffair.guarantee" value="true"
                             :disabled="true"></BaseRadioButton>
            <BaseRadioButton label="Non" name="guarantee" v-model="lightAffair.guarantee" value="false"
                             :disabled="true"></BaseRadioButton>
          </RadioButtonContainer>
        </div>
        <div class="light-affair-row">
          <BaseInput
              v-model="lightAffair.customer"
              label="Client"
              type="text"
              :disabled="true"
          />
          <BaseInput
              v-model="lightAffair.serialNumber"
              label="Numéro de série"
              type="text"
              :disabled="true"
          />
        </div>
        <div class="light-affair-row">
          <BaseDatepicker
              label="Date d'arrivée"
              v-model="lightAffair.receiptDate"
              :disabled="true"
          />
          <BaseDatepicker
              label="Date de livraison"
              v-model="lightAffair.deliveryDate"
              :disabled="true"
          />
        </div>
      </form>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closeDisplayLightAffairModal" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>

  <Modal v-show="displayBilanModal">
      <template v-slot:modalIcon><font-awesome-icon :icon="['fas', 'receipt']"/></template>
      <template v-slot:modalTitle>Générer les bilans d'affaires</template>

      <template v-slot:modalContent>
          <div style="display: flex; flex-direction: row; margin: 20px 10px; width: 1350px;">
              <div style="width: 45%;">
                  <div style="display: flex; flex-direction: row; justify-content: center">
                      <BaseDatepicker
                          v-model="finalReport.startDate"
                          name="finalReportStartDate"
                          label="De"
                          @change="updateAffairsFinalReport"
                      />
                      <BaseDatepicker
                          v-model="finalReport.endDate"
                          name="finalReportEndtDate"
                          label="A"
                          @change="updateAffairsFinalReport"
                      />
                  </div>
                  <div style="display: flex; flex-direction: row">
                      <BaseSelect
                          v-model="finalReport.invoice"
                          name="finalReportInvoice"
                          label="Facture"
                          :defaultOptions="invoiceDefaultOptions"
                          fieldValue="id"
                          fieldLabel="number"
                          @onChange="updateAffairsFinalReport"
                      />
                      <BaseSelect
                          v-model="finalReport.deliveryForm"
                          name="finalReportDeliveryForm"
                          label="Bon de livraison"
                          :defaultOptions="deliveryFormDefaultOptions"
                          fieldValue="id"
                          fieldLabel="number"
                          @onChange="updateAffairsFinalReport"
                      />
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <BaseSelect
                      v-model="finalReport.affair"
                      label="Affaire"
                      name="finalReportAffair"
                      @onChange="updateAffairsFinalReport"
                      fieldValue="id"
                      fieldLabel="name"
                      :searchable="true"
                      api="affair/search"
                      :minChars="2"
                      :maxHeight="300"
                    />
                  </div>
                  <div style="margin: 15px;">
                      <h4 style="margin-bottom: 6px;">Télécharger tous les bilans en fonction des filtres</h4>
                      <div style="display: flex; flex-direction: row; gap: 20px;">
                          <BaseButton class="blue-button" buttonText="PDF" title="Télécharger tous les bilans au format PDF"
                                      @click="downloadMultipleFinalReport(compatibleAffairsFinalReport.map(affair => affair.id), 'pdf')"
                                      :disabled="compatibleAffairsFinalReport.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                              <template v-slot:iconBefore>
                                  <font-awesome-icon :icon="['fas', 'folder-download']"/>
                              </template>
                          </BaseButton>
                          <BaseButton class="green-button" buttonText="Excel" title="Télécharger tous les bilans en Excel"
                                      @click="downloadMultipleFinalReport(compatibleAffairsFinalReport.map(affair => affair.id), 'excel')"
                                      :disabled="compatibleAffairsFinalReport.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                              <template v-slot:iconBefore>
                                  <font-awesome-icon :icon="['fas', 'folder-download']"/>
                              </template>
                          </BaseButton>
                      </div>

                      <h4 style="margin-bottom: 6px; margin-top: 25px;">Télécharger la version compacte</h4>
                      <div style="display: flex; flex-direction: row; gap: 20px">
                          <BaseButton class="blue-button" buttonText="PDF" title="Télécharger la version compacte au format PDF"
                                      @click="downloadLightFinalReport(compatibleAffairsFinalReport.map(affair => affair.id), 'pdf')"
                                      :disabled="compatibleAffairsFinalReport.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                              <template v-slot:iconBefore>
                                  <font-awesome-icon :icon="['fas', 'file-download']"/>
                              </template>
                          </BaseButton>
                          <BaseButton class="green-button" buttonText="Excel" title="Télécharger la version compacte en Excel"
                                      @click="downloadLightFinalReport(compatibleAffairsFinalReport.map(affair => affair.id), 'excel')"
                                      :disabled="compatibleAffairsFinalReport.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                              <template v-slot:iconBefore>
                                  <font-awesome-icon :icon="['fas', 'file-download']"/>
                              </template>
                          </BaseButton>
                      </div>
                      <div v-if="finalReport.selectedAffairs.length > 0">
                        <h4 style="margin-bottom: 6px;">Télécharger les bilans des affaires sélectionnées</h4>
                        <div style="display: flex; flex-direction: row; gap: 20px;">
                          <BaseButton class="blue-button" buttonText="PDF" title="Télécharger en PDF"
                                      @click="downloadMultipleFinalReport(finalReport.selectedAffairs, 'pdf')"
                                      :disabled="finalReport.selectedAffairs.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                              <template v-slot:iconBefore>
                                  <font-awesome-icon :icon="['fas', 'folder-download']"/>
                              </template>
                          </BaseButton>
                          <BaseButton class="green-button" buttonText="Excel" title="Télécharger en Excel"
                                      @click="downloadMultipleFinalReport(finalReport.selectedAffairs, 'Excel')"
                                      :disabled="finalReport.selectedAffairs.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                              <template v-slot:iconBefore>
                                  <font-awesome-icon :icon="['fas', 'folder-download']"/>
                              </template>
                          </BaseButton>
                        </div>
                      </div>
                      

                  </div>
              </div>
              <div style="width: 55%; height: 400px;">
                  <div v-if="compatibleAffairsFinalReport.length > 0" style="margin: 0 2%; height: 100%; overflow-y: auto; width: 92%; padding: 0 2%;">
                      <div v-for="(affair, index) in compatibleAffairsFinalReport" :key="index">
                          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding: 15px 35px; border-radius: 5px; margin-bottom: 15px; box-shadow: 0 3px 10px -2px rgb(0 20 40 / 35%);">
                              <BaseCheckbox 
                                :value="finalReport.selectedAffairs.includes(affair.id)"
                                label="Sélectionner"
                                @onChange="(newVal) => onFinalReportAffairSelectionChanged(affair.id, newVal)"/>
                              <div>{{ affair.affairNumber }}</div>
                              <div>{{ affair.receiptDate }}</div>
                              <div style="display: flex; flex-direction: row;">
                                  <BaseButton class="blue-button" button-text="PDF" title="Télécharger en PDF" @click="downloadFinalReport(affair.id, 'pdf')">
                                      <template v-slot:iconBefore>
                                          <font-awesome-icon :icon="['fas', 'file-download']"/>
                                      </template>
                                  </BaseButton>
                                  <BaseButton class="green-button" button-text="Excel" title="Télécharger en Excel" @click="downloadFinalReport(affair.id, 'excel')">
                                      <template v-slot:iconBefore>
                                          <font-awesome-icon :icon="['fas', 'file-download']"/>
                                      </template>
                                  </BaseButton>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-else style="text-align: center">
                      Aucun résultat
                  </div>
              </div>
          </div>
      </template>

      <template v-slot:modalButtons>
          <BaseButton class="white-button" @click="closeBilanGenerationModal" buttonText="Fermer">
              <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'times']"/>
              </template>
          </BaseButton>
      </template>
  </Modal>

  <Modal v-show="displayIndividualBilanModal">
      <template v-slot:modalIcon><font-awesome-icon :icon="['fas', 'receipt']"/></template>
      <template v-slot:modalTitle>Télécharger le bilan au format :</template>

      <template v-slot:modalContent>
              <div style="display: flex; flex-direction: column; gap: 20px; padding: 40px;">
                  <BaseButton class="blue-button" buttonText="PDF" title="Télécharger tous les bilans au format PDF"
                              @click="downloadFinalReport(currentIndividualBilanAffairId, 'pdf')"
                              :disabled="compatibleAffairsFinalReport.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                      <template v-slot:iconBefore>
                          <font-awesome-icon :icon="['fas', 'receipt']"/>
                      </template>
                  </BaseButton>
                  <BaseButton class="green-button" buttonText="Excel" title="Télécharger tous les bilans en Excel"
                              @click="downloadFinalReport(currentIndividualBilanAffairId, 'excel')"
                              :disabled="compatibleAffairsFinalReport.length === 0" style="width: 100%; padding-left: 0; padding-right: 0; margin-left: 0; margin-right: 0">
                      <template v-slot:iconBefore>
                          <font-awesome-icon :icon="['fas', 'receipt']"/>
                      </template>
                  </BaseButton>
              </div>
      </template>

      <template v-slot:modalButtons>
          <BaseButton class="white-button" @click="closeIndividualFinalReportModal" buttonText="Fermer">
              <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'times']"/>
              </template>
          </BaseButton>
      </template>
  </Modal>

  <PhotoSourceChooser :display-tablet-source-chooser="displaySpindleTabletSourceChooser" @inputFile="takeNewSpindlePicture" @closeModal="closeSpindleTabletChooserModal"/>
  <PhotoSourceChooser :display-tablet-source-chooser="displayDocumentTabletSourceChooser" @inputFile="takeNewDocumentPicture" @closeModal="closeDocumentTabletChooserModal"/>

  <Dialog ref="dialog"/>
</template>

<script>
import ButtonTopbar from "../../components/Topbar/ButtonTopbar";
import {differenceInMilliseconds, differenceInSeconds, format, parse} from "date-fns";
import {fr} from "date-fns/locale";
import {jsPDF} from "jspdf";
import Aggrid from "../../components/Aggrid/Aggrid";
import ListAffairsActionButtonsRenderer from "./ListAffairsActionButtonsRenderer";
import ListTextWithIconRenderer from "../../components/Aggrid/ListTextWithIconRenderer";
import ExpertAssessmentModal from "./ExpertAssessmentModal";
import ConformityReportModal from "./ConformityReportModal";
import OrderDocumentModal from "../OrderDocument/OrderDocumentModal";
import MessagesModal from "../../components/App/MessagesModal";
import Modal from "../../components/App/Modal";
import ModalMenu from "../../components/App/ModalMenu";
import BaseButton from "../../components/Base/BaseButton";
import BaseInput from "../../components/Base/BaseInput";
import BaseSearchingInput from "../../components/Base/BaseSearchingInput";
import RadioButtonContainer from "../../components/App/RadioButtonContainer";
import BaseRadioButton from "../../components/Base/BaseRadioButton";
import BaseSelect from "../../components/Base/BaseSelect";
import BaseDatepicker from "../../components/Base/BaseDatepicker";
import BaseCheckbox from "../../components/Base/BaseCheckbox";
import Photo from "../../components/App/Photo";
import InfiniteList from "../../components/App/InfiniteList";
import Card from "../../components/App/Card";
import ListWithDelete from "../../components/App/ListWithDelete";
import axios from "axios";
import affairStatusEnum from "../../enums/affairStatusEnum";
import config from "../../config";
import changeTypeEnum from "../../enums/changeTypeEnum";
import Dialog from "../../components/App/Dialog";
import {formatDecimal} from "../../utils/formatter";
import systemArticleEnum from "@/enums/systemArticleEnum";
import {createToast} from "mosha-vue-toastify";
import BaseFile from "../../components/Base/BaseFile"
import ArticleKindEnum from "@/enums/articleKindEnum";
import InsuranceStatusEnum from "@/enums/insuranceStatusEnum";
import {isCurrentStatusBefore} from "../../utils/affairStatusOrder";
import NewAffairModal from "./NewAffairModal";
import InterventionReportModal from "@/views/Affair/InterventionReportModal";
import {repairTypesFields} from "@/formFields/repairTypesConditions";
import repairTypeEnum from "@/enums/repairTypeEnum";
import RepairTypeEnum from "@/enums/repairTypeEnum";
import {dataUrlToFile} from "@/utils/dataUrlToFile";
import PhotoSourceChooser from "@/components/App/PhotoSourceChooser.vue";
import store from "@/store";
import {AFFAIR_STATUS_SET_LIST} from "@/store/actions/affairStatus";
import CustomTopbar from "@/components/Topbar/CustomTopbar.vue";

export default {
  name: "Affairs",
  computed: {
    RepairTypeEnum() {
      return RepairTypeEnum
    },
    repairTypeEnum() {
      return repairTypeEnum
    }
  },
  components: {
    CustomTopbar,
    PhotoSourceChooser,
    InterventionReportModal,
    ButtonTopbar,
    ListAffairsActionButtonsRenderer,
    ListTextWithIconRenderer,
    Aggrid,
    MessagesModal,
    Modal,
    ModalMenu,
    BaseButton,
    BaseSelect,
    BaseRadioButton,
    RadioButtonContainer,
    BaseInput,
    BaseSearchingInput,
    BaseDatepicker,
    BaseCheckbox,
    Photo,
    InfiniteList,
    Card,
    Dialog,
    ListWithDelete,
    ExpertAssessmentModal,
    ConformityReportModal,
    OrderDocumentModal,
    BaseFile,
    NewAffairModal
  },
  data() {
    let columnDefs = [{
      field: "affairNumber",
      sortable: true,
      headerName: `Numéro`,
      width: 110,
      lockPosition: true
    }, {
      field: "receiptDate",
      sortable: true,
      headerName: `Date d'entrée`,
      flex: 1,
      lockPosition: true,
      filter: 'agDateColumnFilter'
    }, {
      headerName: `Libellé`,
      flex: 2,
      cellRendererFunctionName: 'AffairSprindleRendererFunction',
      suppressMenu: true
    }];

    if (!this.$store.getters.isCustomer) {
      columnDefs.push({
        headerName: "Garantie",
        flex: 1,
        cellRenderer: "ListTextWithIconRenderer",
        cellRendererParams: {
          field: "guarantee",
          mode: "boolean"
        },
        filterParams: {
          filterOptions: ['equals']
        }
      });
    }

    columnDefs.push({
      headerName: "Statut",
      flex: 2,
      cellRenderer: "ListTextWithIconRenderer",
      cellRendererParams: {
        field: "status",
        mode: this.$store.getters.isCustomer ? "customerStatus" : "status"
      },
      suppressMenu: true
    }, {
      headerName: "",
      width: this.$store.getters.isCustomer ? 275 : 340,
      cellRenderer: "ListAffairsActionButtonsRenderer",
      suppressMenu: true
    });

    if (!this.$store.getters.isCustomer) {
      columnDefs.push({
        field: "deadlineNextTask",
        headerName: `Échéance`,
        flex: 1,
        lockPosition: true,
        cellRendererFunctionName: 'EcheanceRendererFunction',
        suppressMenu: true
      }, {
        field: "affairDuration",
        headerName: `Temps`,
        flex: 1,
        lockPosition: true,
        suppressMenu: true
      }, {
        field: "billToCustomer.name",
        headerName: `Client`,
        flex: 1.5,
        lockPosition: true,
        cellRendererFunctionName: 'ClientRendererFunction'
      }, {
        field: "repairType",
        headerName: `Prestation`,
        flex: 1,
        lockPosition: true,
        cellRendererFunctionName: 'PrestationRendererFunction'
      });
    }

    let manualStatus = [];
    Object.keys(this.$store.getters.getAffairStatusList).forEach(status => {
      /*if (this.$store.getters.getAffairStatusList[status].manual) {*/
        manualStatus.push({
          value: status,
          label: this.$store.getters.getAffairStatusList[status].label
        });
      /*}*/
    });

    return {
      fr,
      affairStatusEnum,
      repairTypesFields,
      columnDefs: columnDefs,
      cellRendererFunctions: new Map([
            [
              'EcheanceRendererFunction',
              function (param) {
                return `
                            ${param?.data?.deadlineNextTask ? `<div>Tâche : ${param?.data?.deadlineNextTask}</div>` : ""}
                            ${param?.data?.deadline ? `<div>Affaire : ${param?.data?.deadline}</div>` : ""}
                        `;
              }
            ], [
              'AffairSprindleRendererFunction',
              function (param) {
                if (param?.data?.classicAffair) {
                  if ([RepairTypeEnum.FOUR, RepairTypeEnum.ISSM, RepairTypeEnum.RETR, RepairTypeEnum.MAIM, RepairTypeEnum.FORM].includes(param?.data?.repairType)) {
                    return `${param?.data?.label}`;
                  } else {
                    return `${param?.data?.spindle?.spindleType.spindleBrand.name ?? param?.data?.spindleBrandLabel ?? "-"}<br/>${param?.data?.spindle?.spindleType.name ?? param?.data?.spindleTypeLabel ?? "-"}<br/>${param?.data?.spindle?.serialNumber ?? "-"}`;
                  }
                } else {
                  return `${param?.data?.label ? param?.data?.label : ''}<br/>${param?.data?.serialNumber ? 'S/N: ' + param?.data?.serialNumber : ''}`;
                }
              }
            ],
            [
              'ClientRendererFunction',
              function (param) {
                if (param?.data?.classicAffair) {
                  return `
                                <div class="break-word">
                                    ${param?.data?.billToCustomer?.name || ''}
                                </div>
                            `;
                } else {
                  return `
                                <div class="break-word">
                                    ${param?.data?.customer || ''}
                                </div>
                            `
                }
              }
            ],
            [
              'PrestationRendererFunction',
              function (param) {
                return param?.data?.repairType?.toUpperCase() || '';
              }
            ]
          ]
      ),
      frameworkComponents: {
        ListAffairsActionButtonsRenderer: ListAffairsActionButtonsRenderer,
        ListTextWithIconRenderer: ListTextWithIconRenderer
      },
      context: null,
      displayError: false,
      displayNomenclatureError: false,
      formErrors: {},
      nomenclatureFormErrors: {},
      displayMessagesModal: false,
      displayAffairModal: false,
      displayCreateAffairModal: false,
      displayUpdateNotValidatedAffairModal: false,
      displayChangeAffairStatusModal: false,
      displayChooseOrderDocumentModelModal: false,
      displayLightAffairModal: false,
      affair: null,
      menuItems: [],
      startAffairModalTab: "spindle",
      repairTypes: [
        {label: "Intervention sur site broches", value: "issb", name: "Intervention"},
        {label: "Remise en état broches", value: "reeb"},
        {label: "Remise en état arbre / sous-ensemble", value: "reea"},
        {label: "Passage sur banc", value: "pass"},
        {label: "Maintenance machine", value: "maim"},
        {label: "Rétrofit machines", value: "retr"},
        {label: "Intervention sur site machines", value: "issm", name: "Intervention"},
        {label: "Spindle hotel", value: "spin"},
        {label: "Fourniture diverses", value: "four"},
        {label: "Formation", value: "form"},
        {label: "Retour sans réparation", value: "rsre"},
        {label: "Retour sans facturation", value: "rsfa"},
        {label: "Ferraillage", value: "ferr"}
      ],
      repairCondition: ["reeb", "reea"],
      establishments: [
        {label: "EBC", value: "ebc"},
        {label: "SMG", value: "smg"}
      ],
      defaultOptions: [],
      manualStatus: manualStatus,
      newStatus: {
        value: affairStatusEnum.PENDING_CUSTOMER_DECISION,
        label: this.$store.getters.getAffairStatusList[
            affairStatusEnum.PENDING_CUSTOMER_DECISION
            ].label
      },
      chooseOrderDocumentModels: [],
      chooseOrderDocumentModel: null,
      chooseOrderDocumentModelMode: '',
      chooseOrderDocumentModelType: '',
      modelRadioButtonDisabled: false,
      form: {
        articlesEstablishmentFilter: null,
        disciplineCurrent: null,
        articleCurrent: null
      },
      isNomenclatureEditable: false,
      displayExpertAssessmentModal: false,
      displayConformityReportModal: false,
      formatDecimal: formatDecimal,
      eaType: {label: "EBC", value: "ebc"},
      eaPDFType: null,
      affairStatusSearch: localStorage.getItem("affairStatusFilter") ? JSON.parse(localStorage.getItem("affairStatusFilter")) : null,
      affairDeadlineMonth: null,
      affairDeadlineYear: null,
      affairSerialNumberSearch: null,
      affairGuaranteeStatusSearch: null,
      affairGuaranteeStatus: [{
        label: 'Acceptée',
        value: 'accepted'
      }, {
        label: 'Refusée',
        value: 'refused'
      }, {
        label: 'En cours',
        value: 'inProgress'
      }],
      affairStatus: Object.keys(this.$store.getters.getAffairStatusList).map(affairStatus => {
        return {
          label: this.$store.getters.getAffairStatusList[affairStatus].label,
          value: affairStatus
        };
      }),
      affairClockingTableSorter : null,
      months: [
        {label: 'Janvier', value: 1},
        {label: 'Février', value: 2},
        {label: 'Mars', value: 3},
        {label: 'Avril', value: 4},
        {label: 'Mai', value: 5},
        {label: 'Juin', value: 6},
        {label: 'Juillet', value: 7},
        {label: 'Août', value: 8},
        {label: 'Septembre', value: 9},
        {label: 'Octobre', value: 10},
        {label: 'Novembre', value: 11},
        {label: 'Décembre', value: 12},
      ],
      years: this.getYears(),
      filter: {
        affairStatus: localStorage.getItem("affairStatusFilter") ? JSON.parse(localStorage.getItem("affairStatusFilter")).value : null,
        affairDeadlineMonth: null,
        affairDeadlineYear: null,
        onlyAffairsInProgress: true,
        search: '',
        affairNumberSearch: '',
        affairLabelSearch: '',
        affairDeleted: false,
      },
      orderDocuments: null,
      quotations: null,
      arOrders: null,
      proFormas: null,
      invoices: null,
      deliveryForms: null,
      displayOrderDocumentModal: false,
      displayQuotationValidationModal: false,
      orderDocumentId: null,
      quotationResponse: true,
      quotationRefusedReason: "",
      quotationValidationFormErrors: {},
      displayQuotationValidationError: false,
      quotationConfirmation: undefined,
      orderDocumentIdToValidate: undefined,
      updateMobileAffairList: 0,
      quotationCustomerOrderNumber: null,
      quotationDocuments: [],
      insuranceStatus: [
        {label: 'Acceptée', value: 'accepted'},
        {label: 'Refusée', value: 'refused'},
        {label: 'Non demandée', value: 'notAsked'},
      ],
      guaranteeStatusOptions: [
        {label: 'Acceptée', value: 'accepted'},
        {label: 'Refusée', value: 'refused'},
        {label: 'En cours', value: 'inProgress'},
      ],
      isCurrentStatusBefore: isCurrentStatusBefore,
      currentTab: null,
      displayInterventionReportModal: false,
      search: '',
      affairNumberSearch: '',
      affairLabelSearch: '',
      searchTimeout: null,
      loadedOrderDocuments: null,
      defaultMachineMaintenanceOptions: [
        {label: 'AVP', value: "avp"},
        {label: 'Sur site (au tps passé)', value: "on_Site"},
        {label: 'Matériel en atelier', value: "workshop_Equipment"}
      ],
      lightAffair: null,
      newSpindlePhotos: [],
      newDocumentPhotos: [],
      displaySpindleTabletSourceChooser: false,
      displayDocumentTabletSourceChooser: false,
      displayBilanModal: false,
      displayIndividualBilanModal: false,
      currentIndividualBilanAffairId: null,
      finalReport: {
          startDate: null,
          endDate: null,
          invoice: null,
          deliveryForm: null,
          affair: null,
          selectedAffairs : []
      },
      invoiceDefaultOptions: null,
      deliveryFormDefaultOptions: null,
      compatibleAffairsFinalReport: []
    };
  },
  watch: {
    startAffairModalTab: {
      handler(currentTab) {
        this.currentTab = currentTab;
        this.handleOrderDocuments();
      }
    }
  },
  beforeMount() {
    this.context = {
      componentParent: this
    };

    this.resetAffair();
    this.resetLightAffair();
  },
  mounted() {
      if (this.$store.getters.isAdmin || this.$store.getters.isWorkshopManager) {
          axios
              .get("affair/getReportDocumentOptions")
              .then((result) => {
                  this.invoiceDefaultOptions = result.data?.defaultOptions?.invoices;
                  this.deliveryFormDefaultOptions =  result.data?.defaultOptions?.deliveryForms;
                  this.compatibleAffairsFinalReport = result.data?.compatibleAffairs;
              })
              .catch(() => {});
      }
    if (this.$store.getters.isCustomer && this.$store.getters.isMobile) {
      window.addEventListener("scroll", () => {
        if (
            window.innerHeight + window.pageYOffset >=
            document.body.offsetHeight - 2
        ) {
          this.emitter.emit("infinite-list-load", {
            name: "affairs"
          });
        }
      });
    }

    if (this.$route.query?.search) {
      let loader = this.$loading.show({
        'background-color': '#000000',
        'color': '#FFFFFF'
      });

      if (this.$route.query?.tab) {
        this.prepareEditAffair(this.$route.query?.search, this.$route.query?.tab, () => {
          if (this.$route.query.orderDocumentId == null) return;

          switch (this.$route.query.tab) {
            case 'quotation':
              this.currentTab = 'quotation';
              break;
          }

          this.handleOrderDocuments();
          this.orderDocumentId = parseInt(this.$route.query.orderDocumentId);
          this.displayOrderDocumentModal = true;
          this.emitter.emit('resizeHeight');

          loader.hide();
        });
      } else {
        this.prepareEditAffair(this.$route.query?.search, null, () => {
          loader.hide();
        });
      }
    }
  },
  methods: {
    parse,
    format,
    goToPlanning() {
      window.location.href = `${window.location.protocol}//${window.location.host}/planning-taches`;
    },
    onSendMessage(messageContent) {
      axios
          .post("message/create", {
            affairId: this.affair.id,
            content: messageContent
          }, {
            showPreloader: true
          })
          .then(response => {
            this.affair.messages.push(response.data);
          })
          .catch(() => {
          });
    },
    getMessagesAndOpenModal(affairId, currentAccount) {
      if (this.affair?.messages == null || this.affair?.messages?.length == 0) {
        axios
            .get(`affair/${affairId}/getMessages`, {
              toastError: true,
              showPreloader: true
            })
            .then(response => {
              this.affair.messages = response.data;
              this.affair.id = affairId;
              this.openMessagesModal();
            })
            .catch(() => {
            });

        axios.post("message/unreadMessage/create", {
          affairId: affairId,
          accountId: currentAccount,
        }, {
          showPreLoader: true
        })
            .then(response => {
              this.emitter.emit('ag-grid-reload');
            })
            .catch(() => {
            })
      } else {
        this.openMessagesModal()
      }
    },
    openMessagesModal() {
      this.displayMessagesModal = true;
    },
    closeMessagesModal() {
      this.displayMessagesModal = false;
      this.affair.messages = null;
    },
    openExpertAssessmentModal() {
      this.$refs.expertAssessmentModal.loadExpertAssessment(this.affair);
      this.displayExpertAssessmentModal = true;
    },
    closeExpertAssessmentModal() {
      this.displayExpertAssessmentModal = false;
    },
    openInterventionReportModal() {
      this.$refs.interventionReportModal.loadInterventionReport(this.affair);
      this.displayInterventionReportModal = true;
    },
    closeInterventionReportModal() {
      this.displayInterventionReportModal = false;
    },
    openConformityReportModal() {
      this.$refs.conformityReportModal.loadConformityReport(this.affair);
      this.displayConformityReportModal = true;
    },
    closeConformityReportModal() {
      this.displayConformityReportModal = false;
    },
    downloadConformityReportPDF() {
      axios
          .get(`conformityReport/download?affairId=${this.affair.id}&reportType=ebc`, {
            toastError: true,
            showPreloader: true
          })
          .then(response => {
            window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}`, "_blank").focus();
            this.refreshAffair();
          })
          .catch(() => {
          });
    },
    downloadConformityReportCheckListPDF() {
      axios
          .get(`conformityReport/checklist/download?affairId=${this.affair.id}`, {
            toastError: true,
            showPreloader: true
          })
          .then(response => {
            window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}`, "_blank").focus();
            this.refreshAffair();
          })
          .catch(() => {
          });
    },
    openChangeAffairStatusModal() {
      this.displayChangeAffairStatusModal = true;
    },
    closeChangeAffairStatusModal() {
      this.displayChangeAffairStatusModal = false;
    },
    closeChooseOrderDocumentModelModal() {
      this.displayChooseOrderDocumentModelModal = false;
    },
    openAffairModal() {
      this.groupArticlesByFamily();
      this.eaPDFType = {label: "EBC", value: "ebc"};
      this.displayAffairModal = true;
    },
    closeAffairModal() {
      this.resetAffair();
      this.displayAffairModal = false;
      this.displayError = false;
      this.displayNomenclatureError = false;
      this.currentTab = this.startAffairModalTab;
    },
    resetAffair() {
      this.affair = {
        receiptDate: new Date(),
        repairType: null,
        establishment: null,
        model: "",
        packaging: "",
        weight: null,
        billToCustomerId: null,
        machineMaintenanceId: null,
        shipToCustomerId: null,
        spindleBrandId: null,
        spindleTypeId: null,
        spindleId: null,
        serialNumber: null,
        guarantee: "",
        customsTransit: "",
        customsTransitDate: null,
        customsImaNumber: null,
        customsExaNumber: null,
        customsValue: null,
        elements: '',
        customerInformations: null,
        spindlePhotos: [],
        documentPhotos: [],
        messages: [],
        nomenclature: null,
        quotation: null,
        statusHistory: [],
        docHistory: {orderDocument: {}},
        orderDocumentsCount: null
      };
      this.loadedOrderDocuments = null;
      this.affairClockingTableSorter = null;
      this.onAffairClockingTableSorterChanged();
    },
    printPackagingCard(affair) {
      const packagingCard = new jsPDF({
        unit: "px",
        format: "a4"
      });

      packagingCard.html(
          `
                    <div style="height: 619px; width: 447px; padding: 20px; box-sizing: border-box;">
                        <div style="border: 1px solid #000000; height: 285px; display: flex; flex-direction: column; align-items: center; justify-content: space-around;">
                            <div style="font-size: 90px;">${affair.affairNumber}</div>
                            <div style="border: 1px solid #000000; height: 32%; width: 50%;font-size: 10px;display: flex;flex-direction: column;justify-content: space-evenly;">
                                <div style="display: flex; justify-content: center;font-weight: bold;">${affair.billToCustomer.name ?? ''}</div>
                                <div style="display: flex; justify-content: center;">${affair.spindleBrandLabel ?? ''} ${affair.spindleTypeLabel ?? ''}</div>
                                <div style="display: flex; justify-content: space-evenly;">
                                    <div style="font-weight: bold;">${affair.affairNumber}</div>
                                    <div>N° SERIE</div>
                                    <div>${affair.spindle.serialNumber}</div>
                                </div>
                                <div style="display: flex; justify-content: space-evenly;">
                                    <div>DATE ARRIVEE</div>
                                    <div>${affair.receiptDate}</div>
                                </div>
                            </div>
                            <div style="font-size: 18px;">N° de Série : ${affair.spindle.serialNumber}</div>
                        </div>
                        <div style="border: 1px solid #000000; height: 285px; display: flex; flex-direction: column; align-items: center; justify-content: space-around; margin-top: 20px;">
                            <div style="font-size: 90px;">${affair.affairNumber}</div>
                            <div style="border: 1px solid #000000; height: 32%; width: 50%;font-size: 10px;display: flex;flex-direction: column;justify-content: space-evenly;">
                                <div style="display: flex; justify-content: center;font-weight: bold;">${affair.billToCustomer.name ?? ''}</div>
                                <div style="display: flex; justify-content: center;">${affair.spindleBrandLabel ?? ''} ${affair.spindleTypeLabel ?? ''}</div>
                                <div style="display: flex; justify-content: space-evenly;">
                                    <div style="font-weight: bold;">${affair.affairNumber}</div>
                                    <div>N° SERIE</div>
                                    <div>${affair.spindle.serialNumber}</div>
                                </div>
                                <div style="display: flex; justify-content: space-evenly;">
                                    <div>DATE ARRIVEE</div>
                                    <div>${affair.receiptDate}</div>
                                </div>
                            </div>
                            <div style="font-size: 18px;">N° de Série : ${affair.spindle.serialNumber}</div>
                        </div>
                    </div>
                `,
          {
            callback: function (packagingCard) {
              packagingCard.save(
                  `FicheCaisse_${affair.affairNumber}.pdf`
              );
            },
            html2canvas: {
              svgRendering: true,
              useCORS: true,
              logging: false
            }
          }
      );
    },
    resetLightAffair() {
      this.lightAffair = {
        id: null,
        affairNumber: null,
        guarantee: "false",
        repairType: "",
        customer: "",
        receiptDate: null,
        deliveryDate: null,
        label: null,
        serialNumber: null
      }
    },
    displayLightAffair(affair) {
      this.lightAffair = affair;
      this.lightAffair.repairType = this.lightAffair?.repairType?.toUpperCase();
      this.displayLightAffairModal = true;
    },
    closeDisplayLightAffairModal() {
      this.displayLightAffairModal = false;
      this.resetLightAffair();
    },
    prepareEditAffair(affairId, startAffairModalTab, callback) {
      if (this.$store.getters.isCustomer) return;
      axios
          .get(`affair/${affairId}`, {
            toastError: true,
            showPreloader: true
          })
          .then(result => {
            let affair = result.data;

            affair.spindlePhotos = affair.spindlePhotos.map(
                photo =>
                    `${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${photo.path}`
            );
            affair.documentPhotos = affair.documentPhotos.map(
                photo =>
                    `${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${photo.path}`
            );

            affair.receiptDate = parse(
                affair.receiptDate,
                "dd/MM/yyyy",
                new Date()
            );
            affair.repairType = this.repairTypes.find(repairType => repairType.value === affair.repairType)
            affair.establishment =
                affair.establishment === "smg"
                    ? {label: "SMG", value: "smg"}
                    : {label: "EBC", value: "ebc"};
            affair.guarantee = affair.guarantee.toString();
            affair.customsTransit = affair.customsTransit.toString();

            affair.billToCustomerId = affair.billToCustomer
                ? {
                  value: affair.billToCustomer?.id,
                  label: affair.billToCustomer?.name
                }
                : {label: "À CRÉER", value: "-1"};

            affair.machineMaintenanceId = affair.machineMaintenance
                ? {
                  value: affair.machineMaintenance,
                  label: affair.machineMaintenance
                }
                : {label: "À CRÉER", value: "-1"};

            affair.shipToCustomerId = affair.shipToCustomer
                ? {
                  value: affair.shipToCustomer?.id,
                  label: affair.shipToCustomer?.name
                }
                : {label: "À CRÉER", value: "-1"};


            if (affair.spindle) {
              affair.serialNumber = {
                value: affair.spindle?.serialNumber,
                id: affair.spindle?.id,
                data: {
                  id: affair.spindle?.id,
                  name: affair.spindle?.serialNumber,
                  model: affair.spindle?.model,
                  spindleType: {
                    id: affair.spindle?.spindleType?.id,
                    name: affair.spindle?.spindleType?.name,
                    spindleBrand: {
                      id:
                      affair.spindle?.spindleType
                          ?.spindleBrand?.id,
                      name:
                      affair.spindle?.spindleType
                          ?.spindleBrand?.name
                    }
                  }
                }
              };
            } else {
              affair.spindleBrandId = (affair.spindleBrand?.data ? affair.spindleBrand :
                      (affair.repairType?.value === RepairTypeEnum.ISSB ? null : {
                        label: "À CRÉER",
                        value: "-1"
                      })
              );
              affair.spindleTypeId = (affair.spindleType?.data ? affair.spindleType :
                      (affair.repairType?.value === RepairTypeEnum.ISSB ? null : {
                        label: "À CRÉER",
                        value: "-1"
                      })
              );
              affair.model = affair.spindleModel;
            }


            affair.customsTransitDate = affair.customsTransitDate
                ? parse(
                    affair.customsTransitDate,
                    "dd/MM/yyyy",
                    new Date()
                )
                : null;

            if (affair.nomenclature != null) {
              affair.nomenclature.articles = affair.nomenclature.articles.map(
                  article => {
                    return {
                      value: article.id,
                      label: article.label,
                      quantity: article.quantity,
                      unit: article.unit,
                      currentQuantity: article.currentQuantity,
                      handleOutOfStock: article.handleOutOfStock,
                      estimatedReceiptDate: article.estimatedReceiptDate,
                      suppliers:
                          article.suppliers != null
                              ? article.suppliers.map(
                                  supplier => {
                                    return {
                                      value: supplier.id,
                                      label: supplier.name
                                    };
                                  }
                              )
                              : null,
                      articleFamily: article.articleFamily,
                      outOfNomenclatureType: article.outOfNomenclatureType,
                      articleKindId: article.articleKindId,
                      rewind: article.rewind,
                      externalMachining: article.externalMachining
                    };
                  }
              );

              affair.nomenclature.disciplines = affair.nomenclature.disciplines.map(
                  discipline => {
                    return {
                      value: discipline.id,
                      label: discipline.label
                    };
                  }
              );               

              affair.machineMaintenance = affair.machineMaintenance ? this.defaultMachineMaintenanceOptions.find(type => type.value === affair.machineMaintenance) : null;

              this.isNomenclatureEditable = affair.quotation == null && affair.status !== affairStatusEnum.ONGOING_TESTS;


              affair.docHistory = {
                conformityReport: affair.docHistory.conformityReport.slice().reverse(),
                expertAssessment: affair.docHistory.expertAssessment.slice().reverse(),
                interventionReport: affair.docHistory.interventionReport.slice().reverse(),
                orderDocument: affair.docHistory.orderDocument
              }
            }

            affair.insuranceStatus = affair.billToCustomer ? this.insuranceStatus.find(insuranceStatus => insuranceStatus.value === affair.billToCustomer.insuranceStatus) : '';
            if (affair.insuranceStatus?.value === InsuranceStatusEnum.ACCEPTED) {
              affair.insuranceStartDate = affair.billToCustomer.insuranceStartDate;
              affair.insuranceEndDate = affair.billToCustomer.insuranceEndDate;
              affair.insuranceAmount = affair.billToCustomer.insuranceAmount;
            }

            affair.guaranteeStatus = this.guaranteeStatusOptions.find(guaranteeStatus => guaranteeStatus.value === affair.guaranteeStatus)

            this.affair = affair;

            if (!affair.isValidated) {
              this.displayUpdateNotValidatedAffairModal = true;
            } else {
              this.loadAffairMenuItems(startAffairModalTab);

              let currentItem = this.menuItems.find(item => item.name === this.currentTab)
              if (currentItem && currentItem.tab) {
                this.getOrderDocuments({event: null, options: {name: currentItem.name, tab: currentItem.tab}})
              }

              this.handleOrderDocuments();
              this.openAffairModal();
            }

            
            this.affairClockingTableSorter = null;
            this.onAffairClockingTableSorterChanged();

            if (typeof callback === 'function') callback();
          })
          .catch(e => {
            if (typeof callback === 'function') callback();
            console.error(e);
          });
    },
    loadAffairMenuItems(startAffairModalTab) {
      this.$nextTick(() => {
        this.$refs.affairModalMenu.handleTabs();
      });

      this.menuItems = this.getMenuItems();

      this.startAffairModalTab = startAffairModalTab || "spindle";
    },
    onFormInputChange(input) {
      if (input.error?.message !== null) {
        this.formErrors[input.name] = input;
      } else {
        delete this.formErrors[input.name];
      }

      switch (input.name) {
        case "billToCustomerId":
          if (input.changeType === changeTypeEnum.USER)
            this.affair.shipToCustomerId = input.value;
          break;
        case "spindleBrandId":
          if (input.value?.value === "-1") {
            this.affair.spindleTypeId = {
              label: "À CRÉER",
              value: "-1"
            };
          } else if (this.affair.spindleTypeId != null && this.affair.spindleTypeId?.value !== "-1" && this.affair.spindleTypeId?.data?.spindleBrand?.id !== input.value?.value) {
            this.affair.spindleTypeId = null;
          }

          if (this.affair.serialNumber != null && this.affair.serialNumber?.data?.spindleType.spindleBrand.id !== input.value?.value && input.changeType === 'user') {
            this.affair.serialNumber = null;
          }
          break;
        case "spindleTypeId":
          if ((this.affair.spindleBrandId === null || this.affair.spindleBrandId?.value === "-1") && this.affair.spindleTypeId != null && this.affair.spindleTypeId?.value !== "-1") {
            this.affair.spindleBrandId = {
              value: input.value.data.spindleBrand.id,
              label: input.value.data.spindleBrand.name
            };
          }

          if (this.affair.serialNumber != null && this.affair.serialNumber?.data?.spindleType.id !== input.value?.value && input.changeType === 'user') {
            this.affair.serialNumber = null;
          }
          break;
        case "serialNumber": {
          if (input.value?.data != null) {
            this.affair.spindleTypeId = {
              value: input.value.data.spindleType.id,
              label: input.value.data.spindleType.name,
              data: {
                spindleBrand: {
                  id: input.value.data.spindleType.spindleBrand.id,
                  name: input.value.data.spindleType.spindleBrand.name
                }
              }
            };

            this.affair.spindleBrandId = {
              value: input.value.data.spindleType.spindleBrand.id,
              label: input.value.data.spindleType.spindleBrand.name
            };

            this.affair.model = input.value.data.model;

            this.modelRadioButtonDisabled = true;
          } else {
            this.modelRadioButtonDisabled = false;
          }
          delete this.formErrors[input.name]
          break;
        }
        case "customsTransit":
          if (input.value !== "true") {
            delete this.formErrors["customsTransitDate"];
            delete this.formErrors["customsImaNumber"];
            delete this.formErrors["customsExaNumber"];
            delete this.formErrors["customsValue"];
          }
          break;
        case 'repairType':
          Object.entries(repairTypesFields).map(([key, value]) => {
            if (!value.required?.includes(this.affair.repairType?.value) && this.formErrors[key]) {
              delete this.formErrors[key];
            }
          })
          break;
        default:
          break;
      }

      if (!this.formErrors.billToCustomerId || this.formErrors.billToCustomerId.error.type === "custom") {
        if (this.affair.billToCustomerId == null || this.affair.billToCustomerId.value === "-1") {
          this.formErrors.billToCustomerId = {
            name: "billToCustomerId",
            value: this.affair.billToCustomerId,
            error: {
              type: "custom",
              message: `Vous devez renseigner un client existant`
            }
          };
        } else {
          delete this.formErrors.billToCustomerId;
        }
      }

      if (!this.formErrors.shipToCustomerId || this.formErrors.shipToCustomerId.error.type === "custom") {
        if (this.affair.shipToCustomerId == null || this.affair.shipToCustomerId.value === "-1") {
          this.formErrors.shipToCustomerId = {
            name: "shipToCustomerId",
            value: this.affair.shipToCustomerId,
            error: {
              type: "custom",
              message: `Vous devez renseigner un client existant`
            }
          };
        } else {
          delete this.formErrors.shipToCustomerId;
        }
      }

      if (!this.formErrors.spindleBrandId || this.formErrors.spindleBrandId.error.type === "custom") {
        if ((this.affair.spindleBrandId == null || this.affair.spindleBrandId.value === "-1") && this.repairTypesFields?.spindleBrandId?.required.includes(this.affair.repairType?.value)) {
          this.formErrors.spindleBrandId = {
            name: "spindleTypeId",
            value: this.affair.spindleBrandId,
            error: {
              type: "custom",
              message: `Vous devez renseigner une marque existante`
            }
          };
        } else {
          delete this.formErrors.spindleBrandId;
        }
      }

      if (!this.formErrors.spindleTypeId || this.formErrors.spindleTypeId.error.type === "custom") {
        if ((this.affair.spindleTypeId == null || this.affair.spindleTypeId.value === "-1") && this.repairTypesFields?.spindleTypeId?.required.includes(this.affair.repairType?.value)) {
          this.formErrors.spindleTypeId = {
            name: "spindleTypeId",
            value: this.affair.spindleTypeId,
            error: {
              type: "custom",
              message: `Vous devez renseigner un type existant`
            }
          };
        } else {
          delete this.formErrors.spindleTypeId;
        }
      }
    },
    onNomenclatureFormInputChange(input) {
      if (input.error?.message !== null) {
        this.nomenclatureFormErrors[input.name] = input;
      } else {
        delete this.nomenclatureFormErrors[input.name];
      }
    },
    checkForm() {
      this.displayError = true;

      return Object.keys(this.formErrors).length === 0;
    },
    checkNomenclatureForm() {
      this.displayNomenclatureError = true;

      return Object.keys(this.nomenclatureFormErrors).length === 0;
    },
    async formateAffair(affair) {
      affair = JSON.parse(JSON.stringify(affair));
      affair.weight = parseInt(affair.weight);
      affair.guarantee = affair.guarantee === "true";
      affair.customsTransit = affair.customsTransit === "true";
      if (affair.customsTransit) {
        affair.customsValue = parseFloat(affair.customsValue);
      }
      affair.repairType = affair.repairType.value;
      affair.establishment = affair.establishment.value;
      affair.billToCustomerId = affair.billToCustomerId.value;
      affair.shipToCustomerId = affair.shipToCustomerId?.value;
      affair.spindleBrandId = affair.spindleBrandId?.value;
      affair.spindleTypeId = affair.spindleTypeId?.value;
      if (affair.serialNumber?.id) {
        affair.spindleId = affair.serialNumber.id;
        delete affair.serialNumber;
        delete affair.model;
      } else {
        affair.serialNumber = affair.serialNumber?.value;
        delete affair.spindleId;
      }
      affair.guaranteeStatus = affair.guaranteeStatus?.value;
      affair.machineMaintenance = affair.machineMaintenance?.value;
      affair.clockingTaskable = affair.clockingTaskable;

      let spindlePhotos = await Promise.all(this.newSpindlePhotos.map(async (base64Image, index) => {
        return await dataUrlToFile(base64Image, `broche-${index}`);
      }));
      let documentPhotos = await Promise.all(this.newDocumentPhotos.map(async (base64Image, index) => {
        return await dataUrlToFile(base64Image, `document-${index}`);
      }));

      let formData = new FormData();
      formData.append("id", affair.id);
      formData.append("receiptDate", affair.receiptDate);
      formData.append("guarantee", affair.guarantee);
      formData.append("packaging", affair.packaging);
      formData.append("weight", affair.weight);
      formData.append("repairType", affair.repairType);
      formData.append("establishment", affair.establishment);
      formData.append("customsTransit", affair.customsTransit);
      if (affair.customsTransit) {
        formData.append(
            "customsTransitDate",
            affair.customsTransitDate
        );
        formData.append("customsImaNumber", affair.customsImaNumber);
        formData.append("customsExaNumber", affair.customsExaNumber);
        formData.append("customsValue", affair.customsValue);
      }
      if (affair.billToCustomerId !== "-1") {
        formData.append("billToCustomerId", affair.billToCustomerId);
      }
      if (affair.shipToCustomerId !== "-1") {
        formData.append("shipToCustomerId", affair.shipToCustomerId);
      }
      if (affair.spindleBrandId !== "-1") {
        formData.append("spindleBrandId", affair.spindleBrandId);
      }
      if (affair.spindleTypeId !== "-1") {
        formData.append("spindleTypeId", affair.spindleTypeId);
      }
      if (affair.spindleId) {
        formData.append("spindleId", affair.spindleId);
      } else if (affair.serialNumber) {
        formData.append("serialNumber", affair.serialNumber);
        formData.append("model", affair.model);
      }
      if (affair.customerInformations != null)
        formData.append(
            "customerInformations",
            affair.customerInformations
        );
      if (affair.insuranceStatus.value === InsuranceStatusEnum.ACCEPTED) {
        formData.append("insuranceAmount", parseFloat(affair.insuranceAmount))
      }
      if (affair.guarantee) {
        formData.append('guaranteeStatus', affair.guaranteeStatus)
      }
      formData.append('elements', affair.elements)
      formData.append('label', affair.label)
      formData.append('machine', affair.machine)
      formData.append('machineMaintenance', affair.machineMaintenance)
      formData.append('internal', affair.internal || false)
      formData.append('clockingTaskable', affair.clockingTaskable)

      spindlePhotos.map((file) => {
        formData.append('newSpindlePhotos', file);
      });
      documentPhotos.map((file) => {
        formData.append('newDocumentPhotos', file);
      });

      return formData;
    },
    async updateAffair() {
      if (!this.checkForm()) return;
      axios
          .put("affair/update", await this.formateAffair(this.affair), {
            toastSuccessMessage: `Modification effectuée`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeAffairModal();
            this.emitter.emit("ag-grid-reload");
            this.newSpindlePhotos = [];
            this.newDocumentPhotos = [];
          })
          .catch(() => {
          });
    },
    changeAffairStatus() {
      this.$refs.dialog
          .show({
            type: "confirm",
            title: "Confirmation",
            message: `Êtes-vous sûr de vouloir changer le statut de cette affaire ?`
          })
          .then(() => {
            axios
                .put("affair/changeStatus/" + this.affair.id, {statusId: this.newStatus.value}, {
                  toastSuccessMessage: `Modification effectuée`,
                  toastError: true,
                  showPreloader: true
                })
                .then(() => {
                  this.closeChangeAffairStatusModal();
                  this.affair.status = this.newStatus.value;
                  this.emitter.emit("ag-grid-reload");
                });
          })
          .catch(() => {
          });
    },
    onDisciplineAdd(discipline) {
      this.$nextTick(() => {
        this.form.disciplineCurrent = null;
      });

      // On ajoute la discipline si elle n'est pas déjà présente
      if (discipline.value != null && this.affair.nomenclature.disciplines.filter(element => element.value === discipline.value.value).length === 0) {
        this.affair.nomenclature.disciplines.push(discipline.value);
      }
    },
    onArticleAdd(article) {
      this.$nextTick(() => {
        this.form.articleCurrent = null;
      });

      // On ajoute l'article s'il n'est pas déjà présent
      if (article.value != null && this.affair.nomenclature.articles.filter(element => element.value === article.value.value).length === 0) {

        this.affair.nomenclature.articles.push({
          ...article.value,
          quantity: 0,
          unit: article.value.data.unit,
          currentQuantity: article.value.data.quantity,
          handleOutOfStock: article.value.data.handleOutOfStock,
          suppliers:
              article.value.data.suppliers != null
                  ? article.value.data.suppliers.map(supplier => {
                    return {
                      value: supplier.id,
                      label: supplier.name
                    };
                  })
                  : null,
          articleFamily: article.value.data.articleFamily
        });
      }

      // Màj du groupement par famille d'articles
      if (article.value != null) {
        this.groupArticlesByFamily();
      }
    },
    formateNomenclature(nomenclature) {
      nomenclature = JSON.parse(JSON.stringify(nomenclature));

      let listOfArticles = [];
      for (let anArticleFamilyGroup of nomenclature.articlesGroupByFamily) {
        for (let anArticle of anArticleFamilyGroup.articles) {
          listOfArticles.push({
            id: anArticle.value,
            quantity: parseFloat(anArticle.quantity),
            suppliers: anArticle.suppliers.map(
                discipline => discipline.value
            )
          });
        }
      }
      nomenclature.articles = listOfArticles;
      nomenclature.disciplines = nomenclature.disciplines.map(
          discipline => discipline.value
      );
      delete nomenclature.documentLink;
      delete nomenclature.technicalDocumentationLink;
      return nomenclature;
    },
    formatePriceRequest(affairId, nomenclature) {
      let suppliers = [];

      for (let article of nomenclature.articles.filter(article => article.articleKindId !== ArticleKindEnum.LABOUR)) {
        for (let articleSupplier of article.suppliers) {
          let supplierIndex = suppliers.findIndex(
              supplier => supplier.id === articleSupplier.value
          );

          if (supplierIndex !== -1) {
            let articleIndex = suppliers[
                supplierIndex
                ].articles.findIndex(
                currentArticle =>
                    currentArticle.id === article.value
            );

            if (articleIndex !== -1) {
              suppliers[supplierIndex].articles[
                  articleIndex
                  ].quantity += parseFloat(article.quantity);
            } else {
              suppliers[supplierIndex].articles.push({
                id: article.value,
                quantity: parseFloat(article.quantity),
                articleKindId: article.articleKindId,
                rewind: article.rewind,
                externalMachining: article.externalMachining
              });
              if (article.articleKindId === -1)
                suppliers[supplierIndex].workOrderArticles.push(this.autoGeneratedFileObject(article));
            }
          } else {
            suppliers.push({
              id: articleSupplier.value,
              articles: [
                {
                  id: article.value,
                  quantity: parseFloat(article.quantity),
                  articleKindId: article.articleKindId,
                  rewind: article.rewind,
                  externalMachining: article.externalMachining
                }
              ],
              workOrderArticles: (article.articleKindId === -1 ? [this.autoGeneratedFileObject(article)] : [])
            });
          }
        }
      }

      let priceRequest = {
        affairId: affairId,
        suppliers: suppliers
      };

      return priceRequest;
    },
    autoGeneratedFileObject(article) {
      return {
        pdfType: (article.rewind ? 'rewind' : (article.externalMachining ? 'externalMachining' : null)),
        path: article.label.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-\ _`~()]/g, "-") + (article.rewind ? "-rebobinage.pdf" : (article.externalMachining ? "-usinage-externe.pdf" : null)),
        editable: true,
        file: null,
        name: (article.rewind ? 'rebobinage' : (article.externalMachining ? 'usinage-externe' : null)),
        articleLabel: article.label
      }
    },
    updateNomenclature(createPriceRequest) {
      if (this.$store.getters.isTechnician) return;
      if (!this.checkNomenclatureForm()) return;

      let allArticles = this.affair.nomenclature.articlesGroupByFamily;
      let allSystems = Object.values(systemArticleEnum).filter(value => value >= systemArticleEnum.SPINDLE_HOTEL);
      let missingSupplier = false;

      for (let family of allArticles) {
        family.articles.forEach(article => {
          if (!Object.values(allSystems).some(value => article.value === value) && !missingSupplier) {
            if (article.suppliers.length === 0) {
              missingSupplier = true;
            }
          }
        });
      }
      if (missingSupplier && createPriceRequest) {
        this.$refs.dialog.show({
          type: 'confirm',
          title: 'Créer les demandes de prix',
          message: `Certains articles n'ont pas de fournisseur associé.<br>Voulez-vous continuer ?`
        }).then(() => {
          axios
              .put(
                  "nomenclature/update",
                  this.formateNomenclature(this.affair.nomenclature),
                  {
                    toastSuccessMessage: !createPriceRequest
                        ? `Nomenclature enregistrée`
                        : null,
                    toastError: true,
                    showPreloader: true
                  }
              )
              .then(() => {
                if (createPriceRequest) {
                  axios
                      .post(
                          "priceRequest/createFromNomenclature",
                          this.formatePriceRequest(
                              this.affair.id,
                              this.affair.nomenclature
                          ),
                          {
                            toastSuccessMessage: `Nomenclature enregistrée et demandes de prix créées`,
                            toastError: true,
                            showPreloader: true
                          }
                      )
                      .then(() => {
                        this.refreshAffair();
                      })
                      .catch(() => {
                      });
                } else {
                  this.refreshAffair();
                }
              })
              .catch(e => {
                console.error(e);
              });
        })
            .catch(() => {
            });
      } else {
        axios
            .put(
                "nomenclature/update",
                this.formateNomenclature(this.affair.nomenclature),
                {
                  toastSuccessMessage: !createPriceRequest
                      ? `Nomenclature enregistrée`
                      : null,
                  toastError: true,
                  showPreloader: true
                }
            )
            .then(() => {
              if (createPriceRequest) {
                axios
                    .post(
                        "priceRequest/createFromNomenclature",
                        this.formatePriceRequest(
                            this.affair.id,
                            this.affair.nomenclature
                        ),
                        {
                          toastSuccessMessage: `Nomenclature enregistrée et demandes de prix créées`,
                          toastError: true,
                          showPreloader: true
                        }
                    )
                    .then(() => {
                      this.refreshAffair();
                    })
                    .catch(() => {
                    });
              } else {
                this.refreshAffair();
              }
            })
            .catch(e => {
              console.error(e);
            });
      }
    },
    downloadNomenclature(){
      axios
          .put(`affair/downloadNomenclature/${this.affair.id}`, {
            toastError: true,
            showPreloader: true
          })
          .then(response => {
                  window.open(`${config.BACK_URL}/${response.data}`, '_blank').focus();
              })
          .catch(() => {
          });
    },
    openDocumentation() {
      window
          .open(this.affair.nomenclature.documentLink, "_blank")
          .focus();
    },
    openTechnicalDocumentationLink() {
      openDocumentation();
    },
    openNomenclatureLink() {
      window
          .open(
              `/types-nomenclatures?search=${this.affair.spindleTypeId.value}`,
              "_blank"
          )
          .focus();
    },
    openAnnex() {
      window
          .open(this.affair.nomenclature.documentLink + "/annexe", "_blank")
          .focus();
    },
    downloadExpertAssessmentPDF() {
      axios
          .get(`expertAssessment/download?affairId=${this.affair.id}&reportType=${this.eaPDFType.value}`, {
            toastError: true,
            showPreloader: true
          })
          .then(response => {
            window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}`, "_blank").focus();
            this.refreshAffair();
          })
          .catch(() => {
          });
    },
    downloadInterventionReportPDF() {
      axios.get(`interventionReport/download?affairId=${this.affair.id}`, {
        toastError: true,
        showPreloader: true
      })
          .then(response => {
            window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}`, "_blank").focus();
            this.refreshAffair();
          })
          .catch(() => {
          });
    },
    onAddCustomer() {
      window.open("/clients?action=addCustomer", "_blank").focus();
    },
    onEditCustomer(e) {
      window.open(`/clients?action=editCustomer&idCustomer=${e.value}`, "_blank").focus();
    },
    onAddSpindleBrand() {
      window.open("/broches?action=addSpindleBrand", "_blank").focus();
    },
    onAddSpindleType() {
      window.open("/broches?action=addSpindleType", "_blank").focus();
    },
    onFiltersChange(input) {
      if (input.changeType === changeTypeEnum.USER) {
        switch (input.name) {
          case "affairStatus":
            if (!this.$store.getters.isTechnician) {
              this.filter[input.name] = input?.value?.value || null;
              if (input?.value) {
                localStorage.setItem("affairStatusFilter", JSON.stringify(input?.value));
              } else {
                localStorage.removeItem("affairStatusFilter");
              }
            }
            break;
          case "affairDeadlineMonth":
            this.filter[input.name] = input?.value?.value || null;
            break;
          case "affairDeadlineYear":
            this.filter[input.name] = input?.value?.value || null;
            break;
          case "affairGuaranteeStatus":
            this.filter[input.name] = input?.value?.value || null;
            break;
          case "affairSerialNumber":
            this.filter[input.name] = input?.value?.value || null;
            break;
        }
      }
    },
    getMenuItems() {
      let statusIndex = 0;

      switch (this.affair.status) {
        case affairStatusEnum.PENDING_EXPERT_ASSESSMENT_PLANNING:
          statusIndex = 1;
          break;
        case affairStatusEnum.PENDING_EXPERT_ASSESSMENT:
        case affairStatusEnum.ONGOING_EXPERT_ASSESSMENT:
        case affairStatusEnum.PENDING_EXPERT_ASSESSMENT_VALIDATION:
          statusIndex = 2;
          break;
        case affairStatusEnum.EXPERT_ASSESSMENT_VALIDATED_PENDING_NOMENCLATURE:
          statusIndex = 3;
          break;
        case affairStatusEnum.NOMENCLATURE_VALIDATED_PENDING_EXPERT_ASSESSMENT:
          statusIndex = 4;
          break;
        case affairStatusEnum.PENDING_QUOTATION:
          statusIndex = 5;
          break;
        case affairStatusEnum.CANCELED:
          statusIndex = 0;
          break;
        case affairStatusEnum.PENDING_CUSTOMER_DECISION:
          if (this.affair?.orderDocumentsQuotation?.filter(orderDocument => orderDocument.status === 'validated').length === 0) {
            statusIndex = 5;
          } else {
            statusIndex = 6;
          }
          break;
        case affairStatusEnum.PENDING_REPAIRS:
        case affairStatusEnum.ONGOING_REPAIRS:
          statusIndex = 6;
          break;
        case affairStatusEnum.PENDING_TESTS_PLANNING:
        case affairStatusEnum.PENDING_TESTS:
        case affairStatusEnum.ONGOING_TESTS:
        case affairStatusEnum.RETURN_WITHOUT_REPAIR:
        case affairStatusEnum.NOT_REPAIRABLE:
          statusIndex = 7;
          break;
        case affairStatusEnum.TO_SHIP:
        case affairStatusEnum.TO_BILL:
        case affairStatusEnum.BILLED:
          statusIndex = 8;
          break;
      }

      let tabs = [{
        name: "spindle",
        label: "Informations sur l'affaire",
        checked: statusIndex >= 1,
        display: true
      }, {
        name: "expertAssessmentPlanning",
        label: "Planification",
        checked: statusIndex >= 2,
        display: !this.checkRepairType([RepairTypeEnum.REEA, RepairTypeEnum.FOUR])
      }, {
        name: "expertAssessment",
        label: "Expertise",
        checked: statusIndex === 3 || statusIndex >= 5,
        display: !this.checkRepairType([RepairTypeEnum.ISSM, RepairTypeEnum.ISSB, RepairTypeEnum.REEA, RepairTypeEnum.FOUR])
      }, {
        name: "interventionReport",
        label: "Intervention",
        checked: statusIndex === 3 || statusIndex >= 5,
        display: this.checkRepairType([RepairTypeEnum.ISSM, RepairTypeEnum.ISSB])
      }, {
        name: "nomenclature",
        label: "Nomenclature",
        checked: statusIndex >= 4,
        display: true
      }, {
        name: "quotation",
        label: "Devis client",
        checked: statusIndex >= 6,
        display: true,
        tab: 'orderDocumentsQuotation'
      }, {
        name: "arOrder",
        label: "AR de commande",
        checked: statusIndex >= 6 && (this.affair?.orderDocumentsArOrder?.length > 0 || this.affair.orderDocumentsCount?.['arOrder'] > 0),
        display: true,
        tab: 'orderDocumentsArOrder'
      }, {
        name: "proForma",
        label: "Pro Forma",
        checked: statusIndex >= 6 && (this.affair?.orderDocumentsProForma?.length > 0 || this.affair.orderDocumentsCount?.['proForma'] > 0),
        display: true,
        tab: 'orderDocumentsProForma'
      }, {
        name: "repair",
        label: "Retrait des stocks",
        checked: statusIndex >= 7,
        display: !this.checkRepairType([RepairTypeEnum.ISSM, RepairTypeEnum.ISSB]),
        alterName: "arOrder",
        tab: 'orderDocumentsArOrder'
      }, {
        name: "conformityReport",
        label: "PV de conformité",
        checked: statusIndex >= 8,
        display: !this.checkRepairType([RepairTypeEnum.ISSM, RepairTypeEnum.ISSB])
      }, {
        name: "deliveryForm",
        label: "Bon de livraison",
        checked: statusIndex >= 8 && (this.affair?.orderDocumentsDeliveryForm?.length > 0 || this.affair.orderDocumentsCount?.['deliveryForm'] > 0),
        display: true,
        tab: 'orderDocumentsDeliveryForm'
      }, {
        name: "invoice",
        label: "Facture",
        checked: statusIndex >= 8 && (this.affair?.orderDocumentsInvoice?.length > 0 || this.affair.orderDocumentsCount?.['invoice'] > 0),
        display: true,
        tab: 'orderDocumentsInvoice'
      }, {
        name: "clocking",
        label: "Pointage",
        checked: false,
        display: true
      }, {
        name: "analytics",
        label: "Analytique",
        display: true
      }, {
        name: "docHistory",
        label: "Historique des documents",
        display: true
      }]

      // onglets à exclure en tant que responsable atelier
      const excludeForManager = ['quotation', 'repair', 'clocking', 'analytics', 'docHistory', 'arOrder', 'proForma', 'invoice', 'deliveryForm']
      if (this.$store.getters.isTechnicianManager) {
        tabs = tabs.filter(tab => !excludeForManager.includes(tab.name))
      }

      return tabs;
    },
    getOrderDocuments(data) {
        let tabName = data?.options?.name;
        
        if (tabName === 'repair') {
            this.currentTab = 'repair';
            this.handleStepRepair();
            return;
        }

      let mode = data?.options?.alterName ?? tabName;
      if ( ((this.affair?.[data?.options?.tab] == null || this.affair?.[data?.options?.tab].length == 0) && this.affair.orderDocumentsCount?.[mode] != null)) {
        axios
            .get(`affair/${this.affair?.id}/getOrderDocuments?mode=${mode}`)
            .then(response => {
              if (mode === 'arOrder') {
                this.affair[data?.options?.tab] = response.data?.orderDocuments
                this.affair.arOrderDate = response.data?.arOrderDate
              } else {
                this.affair[data?.options?.tab] = response.data
              }

              this.currentTab = tabName;
              this.handleOrderDocuments();

            })
            .catch((err) => { console.error(err);
            });

            
      } else {
        this.currentTab = tabName;
        this.handleOrderDocuments();
      }
    },
    withdrawArticle(index) {
      this.$refs.dialog
          .show({
            type: "confirm",
            title: "Confirmation",
            message: `Êtes-vous sûr de vouloir retirer cet article du stock ?`
          })
          .then(() => {
            axios
            .put("affair/withdrawArticle", {
                affairId: this.affair.id,
                articleId: this.affair.articlesStock[index].value,
                quantityToWithdrawal: parseFloat(this.affair.articlesStock[index].quantityToWithdrawal),
                withdrawalDate: this.affair.articlesStock[index].currentStockWithdrawalDate
            }, {
                toastSuccessMessage: `Cet article a bien été retiré du stock`,
                toastError: true,
                showPreloader: true
            })
            .then(() => {
                this.handleStepRepair();
            })
            .catch(() => {
            });
          })
          .catch(() => {
          });
    },
    authorizeRepair() {
      this.$refs.dialog
          .show({
            type: "confirm",
            title: "Confirmation",
            message: `Êtes-vous sûr de vouloir autoriser le démarrage des tests ?`
          })
          .then(() => {
            axios
                .put("affair/authorizeRepair", {affairId: this.affair.id}, {
                  toastSuccessMessage: `Modification effectuée`,
                  toastError: true,
                  showPreloader: true
                })
                .then(() => {
                  this.affair.status = affairStatusEnum.PENDING_TESTS_PLANNING;
                  this.menuItems = this.getMenuItems();
                  this.emitter.emit("ag-grid-reload");
                })
                .catch(() => {
                });
          })
          .catch(() => {
          });
    },
    customerDownloadExpertAssessment(expertAssessmentPath) {
      if (expertAssessmentPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${expertAssessmentPath}`, "_blank").focus();
    },
    customerDownloadConformityReport(conformityReportPath) {
      if (conformityReportPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${conformityReportPath}`, "_blank").focus();
    },
    customerDownloadQuotation(quotationPath) {
      if (quotationPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${quotationPath}`, "_blank").focus();
    },
    customerDownloadInvoice(invoicePath) {
      if (invoicePath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${invoicePath}`, "_blank").focus();
    },
    onArchivedAffairChanged(archived) {
      this.filter.onlyAffairsInProgress = !archived;
    },
    handleRemoveArticle() {
      // Mettre à jour la liste des articles à partir de la liste des groupes de famille d'articles
      let listOfArticles = [];
      for (let anArticleFamilyGroup of this.affair.nomenclature.articlesGroupByFamily) {
        for (let anArticle of anArticleFamilyGroup.articles) {
          listOfArticles.push(anArticle);
        }
      }

      this.affair.nomenclature.articles = listOfArticles;
    },
    groupArticlesByFamily() {
      if (this.affair.nomenclature == null) return;

      let articlesGroupByFamily = [];

      // Génération des articles par famille d'articles
      for (let anArticle of this.affair.nomenclature.articles) {
        // Vérification si on trouve ce groupe de famille d'article
        let indexGroupFamily = articlesGroupByFamily.findIndex(aFamilyGroup => aFamilyGroup.family === anArticle.articleFamily.label);

        // Si le groupe de famille d'article existe on ajoute l'article
        if (indexGroupFamily > -1) {
          articlesGroupByFamily[indexGroupFamily].articles.push(anArticle);
        } else {
          // Sinon on créé le nouveau groupe de famille d'article
          articlesGroupByFamily.push({
            family: anArticle.articleFamily.label,
            order: anArticle.articleFamily.order,
            articles: new Array(anArticle)
          });
        }
      }

      this.affair.nomenclature.articlesGroupByFamily = articlesGroupByFamily.sort(function (a, b) {
        return a.order - b.order;
      });
    },
    handleOrderDocuments() {
      this.quotations = this.affair.orderDocumentsQuotation;
      this.arOrders = this.affair.orderDocumentsArOrder;
      this.proFormas = this.affair.orderDocumentsProForma;
      this.deliveryForms = this.affair.orderDocumentsDeliveryForm;
      this.invoices = this.affair.orderDocumentsInvoice;

      switch (this.currentTab) {
        case 'quotation':
          this.orderDocuments = this.quotations;
          break;
        case 'arOrder':
          this.orderDocuments = this.arOrders;
          break;
        case 'proForma':
          this.orderDocuments = this.proFormas;
          break;
        case 'deliveryForm':
          this.orderDocuments = this.deliveryForms;
          break;
        case 'invoice':
          this.orderDocuments = this.invoices;
          break;
      }

    },
    createOrderDocument(parameters) {
      axios
          .post(`orderDocument/create`, parameters, {
            toastError: true,
            showPreloader: true
          })
          .then((result) => {
            let that = this;

            this.prepareEditAffair(this.affair.id, this.startAffairModalTab, function () {
              that.emitter.emit("ag-grid-reload");
              that.displayOrderDocumentModal = true;
              that.emitter.emit('resizeHeight');
            });
          })
          .catch(() => {
          });
    },
    openOrderDocumentModal(mode) {
      if (this.orderDocuments == null || this.orderDocuments?.length === 0) {
        // S'il n'existe aucun document pour le mode demandé, on va le créer
        this.createNewOrderDocument(mode);
      } else {
        // Sinon, on l'affiche
        this.displayOrderDocumentModal = true;
        this.emitter.emit('resizeHeight');
      }
    },
    validateChooseOrderDocumentModel() {
      let orderDocumentOption = {
        mode: this.chooseOrderDocumentModelMode
      };

      if (this.chooseOrderDocumentModel.value === 'new' || this.chooseOrderDocumentModel.value === 'nomenclature') {
        if (this.chooseOrderDocumentModelMode === 'deliveryForm') {
          orderDocumentOption.affairIds = [this.affair.id];
        } else {
          orderDocumentOption.affairId = this.affair.id;
        }
        if (this.chooseOrderDocumentModel.value === 'new') orderDocumentOption.isEmpty = true;
      } else {
        orderDocumentOption.orderDocumentId = this.chooseOrderDocumentModel.value;
      }

      this.createOrderDocument(orderDocumentOption);
      this.displayChooseOrderDocumentModelModal = false;
    },
    createNewOrderDocument(mode) {
      this.chooseOrderDocumentModelMode = mode;

      if (this.chooseOrderDocumentModelMode === 'arOrder' || this.chooseOrderDocumentModelMode === 'proForma' || this.chooseOrderDocumentModelMode === 'invoice' || this.chooseOrderDocumentModelMode === 'deliveryForm') {
        // Pour ces modes, on récupère les documents "modèles" qui vont permettre de les générer
        axios
            .get(`orderDocument/getRelatedOrderDocuments?affairId=${this.affair.id}&mode=${this.chooseOrderDocumentModelMode}`, {
              toastError: true,
              showPreloader: true
            })
            .then(result => {
              if (result.data.length === 0 && this.chooseOrderDocumentModelMode !== 'deliveryForm') {
                let message = `Merci de valider au moins un devis avant de créer ce document`
                if (this.chooseOrderDocumentModelMode === 'invoice') {
                  message = `Merci de réaliser le bon de livraison avant de créer ce document`
                }

                createToast(message, {
                  position: 'bottom-right',
                  type: 'danger',
                  hideProgressBar: true,
                  showIcon: true,
                  transition: 'slide'
                });
              } else {
                switch (this.chooseOrderDocumentModelMode) {
                  case 'arOrder':
                    this.chooseOrderDocumentModelType = 'AR DE COMMANDE';
                    break;
                  case 'proForma':
                    this.chooseOrderDocumentModelType = 'PRO FORMA';
                    break;
                  case 'invoice':
                    this.chooseOrderDocumentModelType = 'FACTURE';
                    break;
                  case 'deliveryForm':
                    this.chooseOrderDocumentModelType = 'BON DE LIVRAISON';
                    break;
                }

                this.chooseOrderDocumentModels = [{
                  value: 'new',
                  label: '--- Document vierge ---'
                }];

                this.chooseOrderDocumentModels = this.chooseOrderDocumentModels.concat(result.data.map(orderDocument => {
                  return {
                    value: orderDocument.id,
                    label: orderDocument.name
                  }
                }));

                this.chooseOrderDocumentModel = this.chooseOrderDocumentModels[0];
                this.displayChooseOrderDocumentModelModal = true;
              }
            })
            .catch(() => {
            });
      } else {
        this.chooseOrderDocumentModels = [{
            value: 'new',
            label: '--- Document vierge ---'
        }, {
            value: 'nomenclature',
            label: 'À partir de la nomenclature'
        }];

        this.chooseOrderDocumentModel = this.chooseOrderDocumentModels[0];
        this.displayChooseOrderDocumentModelModal = true;
      }
    },
    closeOrderDocumentModal() {
      this.displayOrderDocumentModal = false;
    },
    refreshAffair() {
      this.prepareEditAffair(this.affair.id, this.startAffairModalTab);
      if (this.orderDocuments?.length > 0) {
        const refreshDocumentType = this.orderDocuments[0].type;
        axios
            .get(`affair/${this.affair?.id}/getOrderDocuments?mode=${refreshDocumentType}`)
            .then(response => {
              this.orderDocuments = response.data
            })
            .catch(() => {
            });
      }
      this.emitter.emit("ag-grid-reload");
    },
    openQuotationResponse(orderDocumentId) {
      this.displayQuotationValidationModal = true;
      this.handleDocuments();
      this.orderDocumentIdToValidate = orderDocumentId;
    },
    closeQuotationValidationModal() {
      this.displayQuotationValidationModal = false;
      this.displayQuotationValidationError = false;
      this.quotationDocuments = [];
      this.quotationCustomerOrderNumber = null;
    },
    validateQuotation() {
      delete this.quotationValidationFormErrors["quotationRefusedReason"];
      if (!this.checkQuotationValidationForm()) return;

      this.$refs.dialog
          .show({
            type: "confirm",
            title: "Confirmation",
            message: `En confirmant la validation du devis vous vous engagez à payer la somme présente sur ce dernier.`
          })
          .then(() => {
            axios
                .put(`orderDocument/validate`, this.formateQuotationValidation(), {
                  toastSuccessMessage: `Le devis a été validé`,
                  toastError: true,
                  showPreloader: true
                })
                .then(() => {
                  this.closeQuotationValidationModal();
                  this.emitter.emit('ag-grid-reload');
                  this.updateMobileAffairList += 1;
                })
                .catch(() => {
                });
          })
          .catch(() => {
          });
    },
    formateQuotationValidation() {
      let formData = new FormData();
      if (this.orderDocumentIdToValidate) formData.append('orderDocumentId', this.orderDocumentIdToValidate);
      if (this.quotationCustomerOrderNumber) formData.append('customerOrderNumber', this.quotationCustomerOrderNumber);
      this.quotationDocuments.filter(document => document.id == null && document.file?.length > 0).map(document => formData.append('documents', document.file[0]));
      return formData;
    },
    refuseQuotation() {
      delete this.quotationValidationFormErrors["customerOrderNumber"];
      if (!this.checkQuotationValidationForm()) return;

      axios
          .put(`quotation/refuse/${orderDocumentIdToValidate}`, {reason: this.quotationRefusedReason}, {
            toastSuccessMessage: `Le devis a été refusé`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeQuotationValidationModal();
            this.emitter.emit('ag-grid-reload');
            this.updateMobileAffairList += 1;
          })
          .catch(() => {
          });
    },
    onQuotationValidationFormInputChange(input) {
      if (input.error.message !== null) {
        this.quotationValidationFormErrors[input.name] = input;
      } else {
        delete this.quotationValidationFormErrors[input.name];
      }
    },
    checkQuotationValidationForm() {
      this.displayQuotationValidationError = true;
      return Object.keys(this.quotationValidationFormErrors).length === 0;
    },
    getYears() {
      let currentYear = new Date().getFullYear();
      let years = [];
      while (currentYear >= 2022) {
        years.push({label: currentYear, value: currentYear})
        currentYear--;
      }
      return years;
    },
    getAffairStatusHistory() {
      axios
          .get(`affairStatusHistory/${this.affair.id}`, {
            toastError: true,
            showPreloader: true
          })
          .then((result) => {
            this.affair.statusHistory = result.data;
          })
          .catch(() => {
          });
    },
    openDocFromHistory(docPath) {
      if (docPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${docPath}`, "_blank").focus();
    },
    isValidArticle(articleId) {
      return Object.values(Object.values(systemArticleEnum).filter(value => value > -7)).some(value => articleId === value);
    },
    addArticleToNomenclatureType(event) {
      axios
          .put(`nomenclatureType/updateArticleFromAffair`, {
            affairId: this.affair.id,
            articleId: event.currentTarget.dataset.articleid,
            quantity: event.currentTarget.dataset.quantity
          }, {
            toastError: true,
            showPreloader: true
          })
          .then(() => {
          })
          .catch(() => {
          });
    },
    refreshNomenclatureArticles() {
      axios
          .get(`nomenclature/getArticles?nomenclatureId=${this.affair.nomenclature.id}&affairId=${this.affair.id}`)
          .then((data) => {
            this.affair.nomenclature.articlesGroupByFamily = data.data;
          });
    },
    onDocumentChange() {
      this.handleDocuments();
    },
    handleDocuments() {
      let documents = [];
      let size = 0;
      for (let document of this.quotationDocuments) {
        if (document.file === null) {
          documents.push({
            ...document,
            hide: false
          });
          continue;
        }
        if (document.file.length <= 0) continue;

        size += document.file[0].size / 1000;

        if (size >= 9000) {
          createToast(`La taille totale des pièces jointes ne doit pas excéder 9Mo`, {
            position: 'bottom-right',
            type: 'danger',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });

          break;
        }

        documents.push({
          ...document,
          hide: false
        });
      }

      documents.push({
        name: null,
        description: null,
        path: 'Ajouter un document',
        file: [],
        editable: true,
        hide: true
      });

      this.quotationDocuments = documents;
    },
    openCreateAffairModal() {
      this.displayCreateAffairModal = true;
    },
    closeCreateAffairModal() {
      this.displayCreateAffairModal = false;
    },
    closeUpdateNotValidatedAffairModal() {
      this.displayUpdateNotValidatedAffairModal = false;
    },
    onCreateAffair() {
      this.closeCreateAffairModal();
      this.closeUpdateNotValidatedAffairModal();
      this.emitter.emit('ag-grid-reload');
    },
    createArticle() {
      window.open(`/articles?action=addArticle`, '_blank').focus();
    },
    deleteAffair(affairId, isClassic) {
      this.$refs.dialog.show({
        type: 'confirm',
        title: 'Confirmation',
        message: `Êtes-vous sûr de vouloir supprimer cette affaire ?`
      }).then(() => {
        axios
            .put('affair/changeDeleted/' + affairId, {
              deleted: true,
              isClassic: isClassic
            }, {
              toastSuccessMessage: `Modification effectuée`,
              toastError: true,
              showPreloader: true
            })
            .then(() => {
              this.emitter.emit('ag-grid-reload')
            });
      })
          .catch(() => {
          });
    },
    onCancelDeleteAffair(affairId, isClassic) {
      this.$refs.dialog.show({
        type: 'confirm',
        title: 'Confirmation',
        message: `Êtes-vous sûr de vouloir restaurer cette affaire ?`
      }).then(() => {
        axios
            .put('affair/changeDeleted/' + affairId, {
              deleted: false,
              isClassic: isClassic
            }, {
              toastSuccessMessage: `Modification effectuée`,
              toastError: true,
              showPreloader: true
            })
            .then(() => {
              this.emitter.emit('ag-grid-reload')
            });
      })
          .catch(() => {
          });
    },
    restoreAffair(affairId) {
        this.$refs.dialog.show({
            type: 'confirm',
            title: 'Confirmation',
            message: `Êtes-vous sûr de vouloir reprendre cette affaire ?`
        }).then(() => {
            axios
            .put('affair/restore/' + affairId, {}, {
                toastSuccessMessage: `Affaire reprise`,
                toastError: true,
                showPreloader: true
            })
            .then(() => {
                this.emitter.emit('ag-grid-reload')
            });
        })
        .catch(() => {});
    },
    onSearchInputChange(input) {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.filter[input.name] = input.value
      }, 250)
    },
    checkRepairType(values) {
      return values.includes(this.affair?.repairType?.value)
    },
    takePicture(event, callback) {
        this.displaySpindleTabletSourceChooser = false;
        this.displayDocumentTabletSourceChooser = false;

        let ext = null;
        let file = null;
        if (event.srcElement) {
            file = event.srcElement.files[0];
            ext = event?.srcElement?.files[0].name.match(/\.([^\.]+)$/)[1];
        }
        else {
            file = event;
        }
        if ((event == null || ext == 'pdf') && typeof callback === 'function') {
            callback(null);
            return;
        }

      let reader = new FileReader();
      if (file != null) reader.readAsDataURL(file);

      reader.onload = function () {
        if (typeof callback === 'function') callback(reader);
      };
    },
    takeNewSpindlePicture(event) {

      let that = this;
      this.takePicture(event, (reader) => {
          if (reader == null) return;
        that.newSpindlePhotos.push(reader.result);
        that.affair.spindlePhotos.push(reader.result);
      });
    },
    takeNewDocumentPicture(event) {
      let that = this;
      this.takePicture(event, (reader) => {
          if (reader == null) return;
        that.newDocumentPhotos.push(reader.result);
        that.affair.documentPhotos.push(reader.result);
      });
    },
    closeSpindleTabletChooserModal() {
        this.displaySpindleTabletSourceChooser = false;
    },
    closeDocumentTabletChooserModal() {
        this.displayDocumentTabletSourceChooser = false;
    },
    tabletChooser(event, type) {
        if (!store.getters.isFromAppTablet) return;

        if (type === 'spindle') {
            this.displaySpindleTabletSourceChooser = true
        } else if (type === 'document') {
            this.displayDocumentTabletSourceChooser = true
        }
    },
    onFinalReportAffairSelectionChanged(affairId, selected){
      const foundIndex = this.finalReport.selectedAffairs.findIndex(aId => aId === affairId);
      if(foundIndex !== -1){
        this.finalReport.selectedAffairs.splice(foundIndex, 1);
      }
      if(selected){
        this.finalReport.selectedAffairs.push(affairId);
      }
    },
    downloadFinalReport(affairId, mode = 'pdf') {
        axios
            .put(`affair/downloadReport/${affairId}?mode=${mode}`, {
                toastError: true,
                showPreloader: true
            })
            .then(response => {
                window.open(`${config.BACK_URL}/${response.data}`, '_blank').focus();
            })
            .catch(() => {});
    },
    downloadMultipleFinalReport(affairIds, mode = 'pdf') {
        axios
            .post(`affair/downloadMultipleReport?mode=${mode}`, affairIds, {
                toastError: true,
                showPreloader: true
            })
            .then(response => {
                window.open(`${config.BACK_URL}/${response.data}`).focus();
            })
            .catch(() => {});
    },
    downloadLightFinalReport(affairIds, mode = 'pdf') {
        axios
            .post(`affair/downloadLightFinalReport?mode=${mode}`, affairIds, {
                toastError: true,
                showPreloader: true
            })
            .then(response => {
                window.open(`${config.BACK_URL}/${response.data}`).focus();
            })
            .catch(() => {});
    },
    openBilanGenerationModal() {
      this.displayBilanModal = true;
    },
    closeBilanGenerationModal() {
      this.displayBilanModal = false;
      this.finalReport = {
            startDate: null,
            endDate: null,
            invoice: null,
            deliveryForm: null,
            affair : null,
            selectedAffairs : []
      }
    },
    updateAffairsFinalReport() {
      if (store.getters.isAdmin || store.getters.isWorkshopManager) {
          axios
          .post("affair/getAffairsFinalReport", {
            ...this.finalReport,
            affair : this.finalReport.affair ? this.finalReport.affair.value : null
          })
          .then((result) => {
              this.compatibleAffairsFinalReport = result.data;
          })
          .catch(() => {});
      }
    },
    openIndividualFinalReportModal(affairId) {
        this.currentIndividualBilanAffairId = affairId
        this.displayIndividualBilanModal = true;
    },
    closeIndividualFinalReportModal() {
        this.currentIndividualBilanAffairId = null;
        this.displayIndividualBilanModal = false;
    },
    displayDeletedAffairs(state) {
      this.filter.affairDeleted = state;
    },
    changeAffairClockingTableSorter(columnIndex){
      if(this.affairClockingTableSorter && ((this.affairClockingTableSorter?.columnIndex === columnIndex) && (this.affairClockingTableSorter.sorting === 'asc'))){
        this.affairClockingTableSorter = {
          ...this.affairClockingTableSorter,
          sorting : 'desc'
        }
      }
      else if(this.affairClockingTableSorter && ((this.affairClockingTableSorter?.columnIndex === columnIndex) && (this.affairClockingTableSorter.sorting === 'desc'))){
        this.affairClockingTableSorter = null;
      }
      else {
        this.affairClockingTableSorter = {
          columnIndex: columnIndex,
          sorting : 'asc'
        }
      }
      this.onAffairClockingTableSorterChanged();
    },
    onAffairClockingTableSorterChanged(){
      if(this.affairClockingTableSorter !== null){
        switch (this.affairClockingTableSorter.columnIndex){
          case 0:{
            this.affair.affairTasks.sort((ta, tb) => {
              return (ta.account?.name || '').localeCompare(tb.account?.name || '') * (this.affairClockingTableSorter.sorting === 'asc' ? 1 : -1);
            });
            break;
          }
          case 1:{
            this.affair.affairTasks.sort((ta, tb) => {
              return (ta.discipline?.description || '').localeCompare((tb.discipline?.description || '')) * (this.affairClockingTableSorter.sorting === 'asc' ? 1 : -1);
            });
            break;
          }
          case 2:{
            this.affair.affairTasks.sort((ta, tb) => {
              return ta.startedDate.localeCompare(tb.startedDate) * (this.affairClockingTableSorter.sorting === 'asc' ? 1 : -1);
            });
            break;
          }
          case 3:{
            this.affair.affairTasks.sort((ta, tb) => {
              return ta.endedDate.localeCompare(tb.endedDate) * (this.affairClockingTableSorter.sorting === 'asc' ? 1 : -1);
            });
            break;
          }
          case 4:{
            this.affair.affairTasks.sort((ta, tb) => {
                let taDurationInMinutes = ta.duration.split('H').reduce((acc, time) => (60 * acc) + +time);
                let tbDurationInMinutes = tb.duration.split('H').reduce((acc, time) => (60 * acc) + +time) * (this.affairClockingTableSorter.sorting === 'asc' ? 1 : -1);

                if (taDurationInMinutes < tbDurationInMinutes) {
                    return 1;
                } else if (taDurationInMinutes > tbDurationInMinutes) {
                    return -1;
                } else {
                    return 0;
                }
            });
            break;
          }
        }
      }
      else if(this.affair !== null && this.affair.affairTasks && this.affair.affairTasks.length > 0) {
         this.affair.affairTasks.sort((ta, tb) => {
          let result = (ta.account?.name || '').localeCompare(tb.account?.name || '');
          if(result === 0) {
            result = (ta.startedDate || '').localeCompare(tb.startedDate || '')
          } 
          return result;
        })
      }
    },
    async handleStepRepair() {
        const getQuotations = () => {
            return new Promise((resolve, reject) => {
                axios
                .get(`affair/${this.affair?.id}/getOrderDocuments?mode=quotation`)
                .then(response => {
                    this.affair.orderDocumentsQuotation = response.data;
                    this.handleOrderDocuments();
                    resolve();
                })
                .catch((err) => { console.error(err); reject(); });
            });
        };

        const getArOrders = () => {
            return new Promise((resolve, reject) => {
                axios
                .get(`affair/${this.affair?.id}/getOrderDocuments?mode=arOrder`)
                .then(response => {
                    this.affair.orderDocumentsArOrder = response.data?.orderDocuments
                    this.affair.arOrderDate = response.data?.arOrderDate;
                    this.handleOrderDocuments();
                    resolve();
                })
                .catch((err) => { console.error(err); reject(); });
            });
        };

        await Promise.all([getQuotations(), getArOrders()]);

        // On filtre sur les devis validés
        let validatedQuotations = this.quotations.filter(quotation => quotation.status === 'validated');

        // On récupère les articles du devis que l'on gère en stock
        let articles = [];

        for (let quotation of validatedQuotations) {
            for (let line of quotation.lines) {
                if (line.articleId !== null && line.article.handleOutOfStock) {
                    articles.push({
                        articleId: line.articleId,
                        label: line.article.label,
                        quantity: line.quantity,
                        currentQuantity: line.article.quantity
                    });
                }

                if (line.subLines) {
                    for (let subLine of line.subLines) {
                        if (subLine.articleId !== null && subLine.article.handleOutOfStock) {
                            articles.push({
                                articleId: subLine.articleId,
                                label: subLine.article.label,
                                quantity: subLine.quantity,
                                currentQuantity: subLine.article.quantity
                            });
                        }
                    }
                }
            }
        }

        // On récupère les mouvements de stock déjà effectués
        axios
        .get(`stockMovement/repairByAffair?affairId=${this.affair.id}`)
        .then(response => {
            // On construit les articles en stock 
            this.affair.articlesStock = articles.map(article => {
                let quantityAlreadyWithdrawal = Math.abs(response.data.filter(movement => movement.article.id === article.articleId).reduce((acc, movement) => acc + movement.movement, 0));;
                let quantityToWithdrawal = article.quantity - quantityAlreadyWithdrawal;
                
                return {
                    label: article.label,
                    value: article.articleId,
                    quantity: article.quantity,
                    quantityAlreadyWithdrawal: quantityAlreadyWithdrawal,
                    currentQuantity: article.currentQuantity,
                    quantityToWithdrawal: quantityToWithdrawal <= article.currentQuantity ? Math.max(quantityToWithdrawal, 0) : article.currentQuantity,
                    currentStockWithdrawalDate: format(new Date(), "dd/MM/yyyy HH:mm"),
                }
            });
        })
        .catch((err) => { console.error(err); });
    }
  }
};
</script>
